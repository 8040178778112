import {
  Draggable,
  // Downstream,
  ObjectStorage,
  Db2Database,
  FunctionMath,
  Scalpel,
  ChartBubble,
  Chat,
  ExamMode,
  Workspace,
  NetworkEnterprise,
  Touch_1,
} from '@carbon/icons-react';
import {
  Box,
  Button,
  ChakraProvider,
  Flex,
  Show,
  Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';

const HubSpotFeedbackQueryForm = ({ portalId, formId }) => {
  const [formData, setFormData] = useState({
    firstname: '',
    email: '',
    company: '',
    jobtitle: '',
    country: '',
    help_us_prepare_for_the_demo: '',
    // Add more fields as needed
  });

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();

    const data = {
      fields: Object.entries(formData).map(([name, value]) => ({
        name,
        value,
      })),
      context: {
        pageUri: window.location.href,
        pageName: document.title,
      },
    };

    try {
      const response = await fetch(
        `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      alert(
        'Form submitted successfully. Someone from ZinkML will contact you soon.',
        result
      );
      // Handle success (e.g., show a success message, clear form)
      setFormData({
        firstname: '',
        email: '',
        company: '',
        jobtitle: '',
        country: '',
        help_us_prepare_for_the_demo: '',
        // Add more fields as needed
      });
    } catch (error) {
      alert('Error submitting form', error);
      // Handle error (e.g., show error message)
    }
  };

  let points = [
    <Text style={{ color: 'var(--slate-300)' }} className="text-sm">
      Flexible Drag and Drop Pipelines
    </Text>,
    <Text style={{ color: 'var(--slate-300)' }} className="text-sm">
      Effortless Data Loading from Anywhere: Cloud or Local
    </Text>,
    <Text style={{ color: 'var(--slate-300)' }} className="text-sm">
      Extensive Library for Cleaning, Transformation and Modelling
    </Text>,
    <Text style={{ color: 'var(--slate-300)' }} className="text-sm">
      Precision tools to Slice, Dice and Analyze Data
    </Text>,
    <Text style={{ color: 'var(--slate-300)' }} className="text-sm">
      Dynamic and Interactive Visualizations
    </Text>,
    <Text style={{ color: 'var(--slate-300)' }} className="text-sm">
      Instant Feedback at Every Step
    </Text>,
    <Text style={{ color: 'var(--slate-300)' }} className="text-sm">
      Built for Rapid Prototyping and Iteration
    </Text>,
    <Text style={{ color: 'var(--slate-300)' }} className="text-sm">
      One Platform to Manage your Entire Data Lifecycle
    </Text>,
  ];
  let icons = [
    <Draggable size="16" color="var(--slate-500)" />,
    <ObjectStorage size="16" color="var(--slate-500)" />,
    <Db2Database size="16" color="var(--slate-500)" />,
    <FunctionMath size="16" color="var(--slate-500)" />,
    <Scalpel size="16" color="var(--slate-500)" />,
    <ChartBubble size="16" color="var(--slate-500)" />,
    <Chat size="16" color="var(--slate-500)" />,
    <ExamMode size="16" color="var(--slate-500)" />,
    <Workspace size="16" color="var(--slate-500)" />,
    <NetworkEnterprise size="16" color="var(--slate-500)" />,
  ];

  return (
    <ChakraProvider>
      <Box
        margin="80px auto"
        display="flex"
        flexDirection="row"
        padding="20px"
        maxWidth="1200px"
      >
        <Show above="md">
          <Box width="50%" display="flex" flexDir="column" padding="20px">
            <Text
              fontSize="2rem"
              lineHeight="1.3"
              className="text-5xl"
              fontWeight="600"
              letterSpacing="-.015em"
              bgGradient="linear-gradient(to bottom, var(--orange-200), var(--sky-400))"
              bgClip="text"
              paddingBottom="10px"
              transition="all ease-in-out 300ms"
              textAlign="left"
              _hover={{
                bgGradient:
                  'linear-gradient(45deg, var(--orange-200), var(--sky-400))',
                brightness: '150%',
                transform: 'translatez(10px) scale(1.02)',
                opacity: '1',
              }}
            >
              Feedback/Query for ZinkML Data Science Platform
            </Text>
            <Box
              className="smallDivider"
              width="calc(min(300px, 90%))"
              // margin="16px auto"
              bg="linear-gradient(to right, var(--slate-600), var(--slate-900))"
              marginBottom="20px"
            ></Box>
            <Text
              fontSize={['14px', '16px', '16px']}
              lineHeight="1.6"
              fontWeight="400"
              color="var(--slate-300)"
              paddingBottom="40px"
            >
              A zero-code data science platform designed to solve real world
              challenges faced by&nbsp;
              <b style={{ color: 'var(--sky-300)' }}>
                data scientists
              </b> and <b style={{ color: 'var(--sky-300)' }}>enterprises</b>.
              Focus your time and effort on creating better, newer solutions
              with the ease and efficiency of our end-to-end platform.
            </Text>
            <Flex direction="column" padding="10px" gap="10px" maxW="512px">
              {points.map((point, i) => (
                <Flex
                  gap="10px"
                  // border="1px solid var(--slate-700)"
                  padding="3px"
                  alignItems="center"
                  borderRadius="lg"
                >
                  <Flex
                    height="1.6rem"
                    width="1.6rem"
                    justifyContent="center"
                    alignItems="center"
                    background="var(--slate-800)"
                    border="1px solid var(--slate-700)"
                    borderRadius="full"
                  >
                    {icons[i]}
                  </Flex>
                  <Text>{point}</Text>
                </Flex>
              ))}
            </Flex>
          </Box>
        </Show>
        <Box
          width={{
            base: '100%',
            md: '50%',
            lg: '50%',
            xl: '50%',
            '2xl': '50%',
          }}
          border="1px solid var(--slate-800)"
          borderRadius="20px"
          padding="20px"
        >
          <form
            onSubmit={handleSubmit}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              width: '100%',
              maxWidth: '600px',
            }}
          >
            {/* <label for="firstname" style={{ textAlign: 'right' }}>
          firstname
        </label> */}
            <input
              type="text"
              name="firstname"
              style={{
                color: '#ffffff',
                width: '100%',
                border: '0.5px solid rgba(255, 255, 255, 0.20)',
                height: '3.5rem',
                padding: '1rem',
                position: 'relative',
                fontSize: '1rem',
                background: '#26272A',
                transition: '0.3s ease border',
                borderRadius: '0.75rem',
              }}
              value={formData.firstname}
              onChange={handleChange}
              placeholder="Name"
            />
            {/* <label for="email" style={{ textAlign: 'right' }}>
          Email
        </label> */}
            <input
              type="email"
              name="email"
              style={{
                color: '#ffffff',
                width: '100%',
                border: '0.5px solid rgba(255, 255, 255, 0.20)',
                height: '3.5rem',
                padding: '1rem',
                position: 'relative',
                fontSize: '1rem',
                background: '#26272A',
                transition: '0.3s ease border',
                borderRadius: '0.75rem',
              }}
              value={formData.email}
              onChange={handleChange}
              placeholder="Email*"
              required
            />
            <input
              type="text"
              name="company"
              style={{
                color: '#ffffff',
                width: '100%',
                border: '0.5px solid rgba(255, 255, 255, 0.20)',
                height: '3.5rem',
                padding: '1rem',
                position: 'relative',
                fontSize: '1rem',
                background: '#26272A',
                transition: '0.3s ease border',
                borderRadius: '0.75rem',
              }}
              value={formData.company}
              onChange={handleChange}
              placeholder="Company"
            />

            {/* <label for="lastname" style={{ textAlign: 'right' }}>
          lastname
        </label> */}
            <input
              type="text"
              name="jobtitle"
              style={{
                color: '#ffffff',
                width: '100%',
                border: '0.5px solid rgba(255, 255, 255, 0.20)',
                height: '3.5rem',
                padding: '1rem',
                position: 'relative',
                fontSize: '1rem',
                background: '#26272A',
                transition: '0.3s ease border',
                borderRadius: '0.75rem',
              }}
              value={formData.jobtitle}
              onChange={handleChange}
              placeholder="Job Title"
            />
            <input
              type="text"
              name="country"
              style={{
                color: '#ffffff',
                width: '100%',
                border: '0.5px solid rgba(255, 255, 255, 0.20)',
                height: '3.5rem',
                padding: '1rem',
                position: 'relative',
                fontSize: '1rem',
                background: '#26272A',
                transition: '0.3s ease border',
                borderRadius: '0.75rem',
              }}
              value={formData.country}
              onChange={handleChange}
              placeholder="Country"
            />
            <textarea
              name="help_us_prepare_for_the_demo"
              style={{
                color: '#ffffff',
                width: '100%',
                border: '0.5px solid rgba(255, 255, 255, 0.20)',
                // height: '3.5rem',
                minHeight: '7rem',
                padding: '1rem',
                position: 'relative',
                fontSize: '1rem',
                background: '#26272A',
                transition: '0.3s ease border',
                borderRadius: '0.75rem',
              }}
              value={formData.your_feedback_query}
              onChange={handleChange}
              placeholder="Your feedback/query"
            />
            {/* Add more form fields as needed */}
            <span
              style={{
                color: 'rgb(175, 179, 184)',
                fontSize: '0.75rem',
              }}
            >
              * Required
            </span>
            <Button
              type="submit"
              style={{
                gap: '0.5rem',
                color: 'var(--slate-200)',
                border: '1px solid rgba(255, 255, 255, 0.4)',
                cursor: 'pointer',
                display: 'flex',
                padding: '1.25rem 2rem',
                fontSize: '1rem',
                boxShadow:
                  '0 0 1px 0px rgba(255, 255, 255, 0.4) inset, 0 0 1px 0px rgba(255, 255, 255, 0.4)',
                transition: 'all 0.3s ease',
                alignItems: 'center',
                fontWeight: '500',
                lineHeight: '1',
                justifyContent: 'center',
                background:
                  'linear-gradient(#161628e0, #161628e0) padding-box,linear-gradient(to right, var(--pink-300), var(--teal-400)) border-box',
                borderRadius: 'var(--round-l)',
              }}
              _hover={{
                background:
                  'linear-gradient(#161628d0, #161628d0) padding-box,linear-gradient(to right, var(--pink-300), var(--teal-400)) border-box !important',
                scale: '1.1 !important',
              }}
            >
              <Touch_1 size="20" />
              Submit
            </Button>
          </form>
        </Box>
      </Box>
    </ChakraProvider>
  );
};

export default HubSpotFeedbackQueryForm;

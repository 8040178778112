import React from 'react';
import { Flex, Grid, Text, Center, Box, Image, Show } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import InPageButton from './InPageButton';
import { DataEnrichment } from '@carbon/icons-react';
// import KnowMoreDivider from './KnowMoreDivider';

let learnIcon = <DataEnrichment fill="var(--slate-400)" />;
export function RightSideImage(props) {
  return (
    <Grid
      templateColumns="repeat(12,minmax(0,1fr))"
      gap="0"
      // paddingY="2rem"
      // paddingX="5"
      padding="0"
      width="100%"
      boxShadow="0 0 0 1px #00000020, 0 9px 9px #0000000f, 0 7px 7px #0000000d, 0 5px 4px #0000000a, 0 3px 3px #00000008, 0 2px 2px #00000005"
      borderTop="1px solid var(--slate-800)"
      borderRadius="var(--round-xxl)"
      bg="linear-gradient(145deg, #102024, #1a1a1a 50%)"
      overflow="hidden"
    >
      <Flex
        gridColumn={['span 12', 'span 6', 'span 6']}
        direction="column"
        justify="center"
        // paddingX="5"
        // paddingY="10"
        padding="2rem 1rem 2rem 2rem"
        gap="10px"
        maxWidth="550px"
        minHeight="360px"
      >
        <Text
          className="text-4xl"
          // fontSize={['1.875rem', '2.25rem', '3rem']}
          fontWeight="500"
          // color={props.item.color}
          bgGradient="linear-gradient(0deg, var(--purple-xx-300), var(--sky-200))"
          bgClip="text"
          paddingBottom="6px"
          textShadow="0 1px #00000029"
        >
          {props.item.heading}
        </Text>
        {props.item.subItems.map(subItem => {
          return (
            <>
              <Text
                fontSize="21px"
                fontWeight="500"
                // color={props.item.color}
                color="transparent"
                bgGradient="linear-gradient(0deg, var(--sky-400), var(--teal-200))"
                bgClip="text"
                textShadow="0 1px #00000029"
                // paddingLeft="10px"
              >
                {subItem.subHeading}
              </Text>
              <Text
                fontSize="14px"
                lineHeight="1.6"
                fontWeight="400"
                paddingBottom="12px"
                maxWidth="420px"
                color="var(--slate-400)"
                _hover={{
                  color: 'var(--sky-100)',
                  transform: 'translatez(10px) scale(1.02)',
                  opacity: '1',
                }}
                // paddingLeft="10px"
              >
                {subItem.paraText}
              </Text>
            </>
          );
        })}
        {/* {props.comp} */}
        <Flex>
          <InPageButton
            link={props.item.link}
            text={props.item.linkText}
            icon={props.item.linkIcon}
          />
        </Flex>
      </Flex>
      <Flex
        gridColumn={['span 12', 'span 6', 'span 6']}
        // bg="var(--neutral-800)"
        bg="var(--bg-default)"
        borderRadius="0"
        minH={['none', '340px', '340px', '340px', '340px']}
        padding="0"
        overflow="hidden"
        justifyContent="center"
      >
        <img
          src={props.item.subItems[0].subImage}
          style={{ objectFit: 'cover' }}
        />
        {props.item.subItems[0].subSvg}
      </Flex>
    </Grid>
  );
}
export function LeftSideImage(props) {
  return (
    <Grid
      templateColumns="repeat(12,minmax(0,1fr))"
      gap="0"
      // paddingY="2rem"
      // paddingX="5"
      padding="0"
      width="100%"
      boxShadow="0 0 0 1px #00000240, 0 9px 9px #0000000f, 0 7px 7px #0000000d, 0 5px 4px #0000000a, 0 3px 3px #00000008, 0 2px 2px #00000005"
      borderTop="1px solid var(--slate-800)"
      borderRadius="var(--round-xxl)"
      bg="linear-gradient(145deg, #1a1a1a 30%, #102024 100%)"
      overflow="hidden"
    >
      <Flex
        gridColumn={['span 12', 'span 6', 'span 6']}
        // bg="var(--neutral-800)"
        bg="var(--bg-default)"
        borderRadius="0"
        minH={['none', '340px', '340px', '340px', '340px']}
        padding="0"
        overflow="hidden"
        justifyContent="center"
        // maxWidth="450px"
      >
        <img
          src={props.item.subItems[0].subImage}
          style={{ objectFit: 'cover' }}
        />
        {/* <Image
          src={props.item.subItems[0].subSvg}
          className={props.item.svgClass}
          height="256px"
          alignSelf="center"
        /> */}
        {props.item.subItems[0].subSvg}
      </Flex>
      <Flex
        gridColumn={['span 12', 'span 6', 'span 6']}
        direction="column"
        justify="center"
        // paddingX="5"
        // paddingY="10"
        // padding="0"
        padding={[
          '1.5rem 1rem 1.5rem 1rem',
          '2rem 1rem 2rem 2rem',
          '2rem 1rem 2rem 2rem',
          '2rem 1rem 2rem 2rem',
          '2rem 1rem 2rem 2rem',
        ]}
        gap="10px"
        maxWidth="550px"
        minHeight="360px"
      >
        <Text
          className="text-4xl"
          fontSize={['1.8rem', '2.25rem', '2.25rem', '2.25rem', '2.25rem']}
          lineHeight={['2.0rem', '2.5rem', '2.5rem', '2.5rem', '2.5rem']}
          fontWeight="500"
          // color={props.item.color}
          bgGradient="linear-gradient(0deg, var(--purple-xx-300), var(--sky-200))"
          bgClip="text"
          paddingBottom="6px"
          textShadow="0 1px #00000029"
        >
          {props.item.heading}
        </Text>
        {props.item.subItems.map(subItem => {
          return (
            <>
              <Text
                fontSize="21px"
                fontWeight="500"
                color="transparent"
                bgGradient="linear-gradient(0deg, var(--sky-400), var(--teal-200))"
                bgClip="text"
                textShadow="0 1px #00000029"
                // paddingLeft="10px"
              >
                {subItem.subHeading}
              </Text>
              <Text
                fontSize="14px"
                lineHeight="1.6"
                fontWeight="400"
                paddingBottom="12px"
                maxWidth="420px"
                color="var(--slate-400)"
                _hover={{
                  color: 'var(--sky-100)',
                  transform: 'translatez(10px) scale(1.02)',
                  opacity: '1',
                }}
                // paddingLeft="10px"
              >
                {subItem.paraText}
              </Text>
            </>
          );
        })}
        <Flex>
          <InPageButton
            link={props.item.link}
            text={props.item.linkText}
            icon={props.item.linkIcon}
          />
        </Flex>
      </Flex>
    </Grid>
  );
}
export function AlternateHalfGrid(props) {
  let rightSide = true;
  return (
    <Flex
      className="observer1 "
      data-id="3"
      id="USPs"
      width="100vw"
      justify="center"
      paddingTop="4rem"
      paddingBottom="2rem"
      // bg="#f5f5f7"
      // color="#1d1d1f"
      flexDir="column"
      gap="2rem"
    >
      <Text
        // fontSize={['40px', '40px', '50px', '60px', '60px']}
        // lineHeight="1.35"
        // className="text-5xl"
        lineHeight={['1.47', '1.33', '1.2', '1.2']}
        fontSize={['30px', '30px', '40px', '44px', '44px']}
        fontWeight="500"
        letterSpacing="-.015em"
        bgGradient="linear-gradient(0deg, var(--slate-500), var(--slate-300))"
        bgClip="text"
        transition="all ease-in-out 300ms"
        textAlign="center"
        textShadow="0 1px #00000029"
        _hover={{
          transform: 'translatez(10px) scale(1.02)',
          opacity: '1',
        }}
      >
        {props.heading}
        <Box
          className="smallDivider"
          width="calc(min(300px, 90%))"
          margin={[
            '12px auto',
            '24px auto',
            '24px auto',
            '24px auto',
            '24px auto',
          ]}
          bg="linear-gradient(45deg, var(--slate-900),var(--slate-500), var(--slate-900))"
        ></Box>
      </Text>

      <Flex
        width="calc(min(1100px, 96%))"
        direction="column"
        margin="0 auto"
        gap="2.5rem"
      >
        <Show above="md">
          {props.benefits.map(benefit => {
            if (rightSide) {
              rightSide = false;
              return <RightSideImage item={benefit} />;
            } else {
              rightSide = true;
              return <LeftSideImage item={benefit} />;
            }
          })}
        </Show>
        <Show below="md">
          {props.benefits.map(benefit => {
            return <LeftSideImage item={benefit} />;
            // if (rightSide) {
            //   rightSide = false;
            //   return <RightSideImage item={benefit} />;
            // } else {
            //   rightSide = true;
            //   return <LeftSideImage item={benefit} />;
            // }
          })}
        </Show>
        {/* <RightSideImage /> */}

        {/* <Center
          className="smallDivider"
          width="calc(100% - 40px)"
          color="myTertiary.500"
          alignSelf="center"
          marginY="10"
        ></Center> */}
        {/* <KnowMoreDivider link="/Product" text="Learn more about our USPs" /> */}
      </Flex>
    </Flex>
  );
}
export function AlternateHalfGridMobile(props) {
  // let rightSide = true;
  return (
    <Flex
      width="100vw"
      justify="center"
      paddingX="2"
      gap="2"
      // bg="#f5f5f7"
      // color="#1d1d1f"
    >
      <Flex maxWidth="1080px" width="100%" direction="column">
        {/* <RightSideImage /> */}
        {props.benefits.map(benefit => {
          return <RightSideImage item={benefit} />;
        })}
        <Center
          className="smallDivider"
          width="calc(100% - 40px)"
          color="myTertiary.500"
          alignSelf="center"
          marginY="10"
        ></Center>
        {/* <KnowMoreDivider link="/Product" text="Learn more about our USPs" /> */}
      </Flex>
    </Flex>
  );
}
// Colors Set
// --violet: #8876fe;
// --magenta: #d71bce;
// --plum: #8d1287;
// --indigo: #622aff;
// --grape: #6529f8;
// --azure: #027fff;
// --tomato: #f63e54;
// --orange: #e66702;
// --green: #0c9d46;
// --forest: #0c8346;
// --sea-green: #099b9d;
// --teal: #049cb7;
// --aqua: #039e8f;
// --moonstone: #2294b1;
// --midnight: #1b23b3;
// --sapphire: #3d44c3;

import { Flex, Text, Box, ChakraProvider, Grid, Link } from '@chakra-ui/react';
import react from 'react';

export default function AboutUsCareers() {
  return (
    <ChakraProvider>
      <Flex
        className="observer3"
        data-id="3"
        id="careers"
        width="100vw"
        justify="center"
        paddingY="4rem"
        // bg="#f5f5f7"
        // color="#1d1d1f"
        flexDir="column"
        gap="2rem"
        alignItems="center"
      >
        <Text
          // fontSize={['40px', '40px', '50px', '60px', '60px']}
          lineHeight="1.35"
          fontSize={['30px', '40px', '50px', '50px', '50px']}
          fontWeight="600"
          letterSpacing="-.015em"
          bgGradient="linear-gradient(0deg, var(--slate-500), var(--slate-300))"
          bgClip="text"
          transition="all ease-in-out 300ms"
          textAlign="center"
          _hover={{
            bgGradient:
              'linear-gradient(0deg, var(--slate-500), var(--slate-300))',
            transform: 'translatez(10px) scale(1.02)',
            opacity: '1',
          }}
        >
          Careers
          <Box
            className="smallDivider"
            width="calc(min(700px, 90%))"
            margin="24px auto"
            bg="linear-gradient(45deg, var(--slate-900),var(--slate-500), var(--slate-900))"
          ></Box>
        </Text>
        <Flex justifyContent="flex-end" width="calc(min(800px, 96%))">
          <Text
            fontSize={['14px', '18px', '18px']}
            fontWeight="400"
            color="var(--slate-300)"
            textAlign="center"
          >
            Joining our team means becoming part of a dynamic and innovative
            company that is shaping the future of data science. We're looking
            for passionate individuals who are excited about the intersection of
            data, technology, and business. Whether you're an experienced data
            scientist or a recent graduate eager to learn, we have a place for
            you to thrive.
          </Text>
        </Flex>
        <Text
          // fontSize={['40px', '40px', '50px', '60px', '60px']}
          lineHeight="1.35"
          className="text-3xl"
          fontWeight="500"
          letterSpacing="-.015em"
          bgGradient="linear-gradient(0deg, var(--slate-500), var(--slate-300))"
          bgClip="text"
          transition="all ease-in-out 300ms"
          textAlign="center"
        >
          Roles
          <Box
            className="smallDivider"
            width="calc(min(700px, 90%))"
            margin="24px auto"
            bg="linear-gradient(45deg, var(--slate-900),var(--slate-500), var(--slate-900))"
          ></Box>
        </Text>

        <Flex
          width="calc(min(900px, 96%))"
          direction="column"
          margin="0 auto"
          gap="4rem"
        >
          <Grid
            templateColumns={[
              'repeat(1,minmax(0,1fr))',
              'repeat(2,minmax(0,1fr))',
              'repeat(2,minmax(0,1fr))',
              'repeat(3,minmax(0,1fr))',
              'repeat(3,minmax(0,1fr))',
            ]}
            gap="1rem"
            // paddingY="2rem"
            // paddingX="5"
            padding="0"
            width="100%"
            overflow="hidden"
          >
            <Flex
              gridColumn={['span 1', 'span 1', 'span 1']}
              direction="column"
              justify="top"
              padding="2rem 2rem 2rem 2rem"
              gap="5px"
              bg="linear-gradient(145deg, #24281e, #1d2224 70%)"
              borderRadius="var(--round-l)"
            >
              <Text
                className="text-xl"
                lineHeight="1.5rem"
                fontWeight="500"
                bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                bgClip="text"
                paddingBottom="6px"
              >
                Software Engineer
              </Text>
              <Text
                fontSize="14px"
                fontWeight="400"
                paddingBottom="12px"
                color="var(--slate-400)"
              >
                Help us build and improve our zero-code platform by developing
                new features and optimizing existing ones.
                <br />
              </Text>
            </Flex>
            <Flex
              gridColumn={['span 1', 'span 1', 'span 1']}
              direction="column"
              justify="top"
              padding="2rem 2rem 2rem 2rem"
              gap="5px"
              bg="linear-gradient(145deg, #24281e, #1d2224 70%)"
              borderRadius="var(--round-l)"
            >
              <Text
                className="text-xl"
                lineHeight="1.5rem"
                fontWeight="500"
                bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                bgClip="text"
                paddingBottom="6px"
              >
                Data Scientist
              </Text>
              <Text
                fontSize="14px"
                fontWeight="400"
                paddingBottom="12px"
                color="var(--slate-400)"
              >
                Join our data science team to develop and deploy machine
                learning models that drive insights and create value for our
                customers.
                <br />
              </Text>
            </Flex>
            <Flex
              gridColumn={['span 1', 'span 1', 'span 1']}
              direction="column"
              justify="top"
              padding="2rem 2rem 2rem 2rem"
              gap="5px"
              bg="linear-gradient(145deg, #24281e, #1d2224 70%)"
              borderRadius="var(--round-l)"
            >
              <Text
                className="text-xl"
                lineHeight="1.5rem"
                fontWeight="500"
                bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                bgClip="text"
                paddingBottom="6px"
              >
                Product Manager
              </Text>
              <Text
                fontSize="14px"
                fontWeight="400"
                paddingBottom="12px"
                color="var(--slate-400)"
              >
                Drive the development of our data science platform by working
                closely with our engineering and design teams to define and
                prioritize features.
                <br />
              </Text>
            </Flex>
            <Flex
              gridColumn={['span 1', 'span 1', 'span 1']}
              direction="column"
              justify="top"
              padding="2rem 2rem 2rem 2rem"
              gap="5px"
              bg="linear-gradient(145deg, #24281e, #1d2224 70%)"
              borderRadius="var(--round-l)"
            >
              <Text
                className="text-xl"
                lineHeight="1.5rem"
                fontWeight="500"
                bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                bgClip="text"
                paddingBottom="6px"
              >
                Marketing Manager
              </Text>
              <Text
                fontSize="14px"
                fontWeight="400"
                paddingBottom="12px"
                color="var(--slate-400)"
              >
                Help us tell our story and reach a broader audience by leading
                our marketing efforts.
                <br />
              </Text>
            </Flex>
            <Flex
              gridColumn={['span 1', 'span 1', 'span 1']}
              direction="column"
              justify="top"
              padding="2rem 2rem 2rem 2rem"
              gap="5px"
              bg="linear-gradient(145deg, #24281e, #1d2224 70%)"
              borderRadius="var(--round-l)"
            >
              <Text
                className="text-xl"
                lineHeight="1.5rem"
                fontWeight="500"
                bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                bgClip="text"
                paddingBottom="6px"
              >
                Content Manager
              </Text>
              <Text
                fontSize="14px"
                fontWeight="400"
                paddingBottom="12px"
                color="var(--slate-400)"
              >
                Help us in content creation, strategy, and publication, ensuring
                alignment with goals, quality, SEO, and audience engagement.
                <br />
              </Text>
            </Flex>
            <Flex
              gridColumn={['span 1', 'span 1', 'span 1']}
              direction="column"
              justify="top"
              padding="2rem 2rem 2rem 2rem"
              gap="5px"
              bg="linear-gradient(145deg, #24281e, #1d2224 70%)"
              borderRadius="var(--round-l)"
            >
              <Text
                className="text-xl"
                lineHeight="1.5rem"
                fontWeight="500"
                bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                bgClip="text"
                paddingBottom="6px"
              >
                UI/UX Designer
              </Text>
              <Text
                fontSize="14px"
                fontWeight="400"
                paddingBottom="12px"
                color="var(--slate-400)"
              >
                Shape the user experience and help create visually appealing
                user interfaces that make complex data science processes
                accessible to all.
                <br />
              </Text>
            </Flex>
          </Grid>
        </Flex>
        <Flex
          justifyContent="flex-end"
          width="calc(min(800px, 96%))"
          flexDir="column"
          gap="30px"
        >
          <Text
            fontSize="14px"
            fontWeight="400"
            color="var(--slate-400)"
            textAlign="center"
          >
            We are also actively looking for passionate individuals from all
            fields who are ready to embark on an exciting journey into the world
            of data science with an internship opportunity. Don't see a role
            that matches your skills? We're always on the lookout for
            exceptional talent. Feel free to reach out to us with your resume
            and a brief introduction at{' '}
            <Link href="mailto:info@zinkml.com" color="var(--blue-400)">
              ZinkML
            </Link>
            .
          </Text>
        </Flex>
      </Flex>
    </ChakraProvider>
  );
}

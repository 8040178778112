import { ChakraProvider, Flex, Link } from '@chakra-ui/react';
import React from 'react';

export default function SecondaryNavComp(props) {
  return (
    <ChakraProvider>
      <Flex
        bg="var(--bg-5)"
        width="100vw"
        height="5rem"
        justifyContent="center"
        paddingTop="3rem"
        position="fixed"
        top="0"
        overflow="hidden"
        zIndex="9996"
        boxShadow="0 1px 6px 0px #00000060"
      >
        <Flex
          width="1280px"
          justify="space-between"
          paddingX="5"
          alignItems="center"
        >
          <Flex>
            <Link
              color="var(--sky-50)"
              _hover={{ color: 'var(--purple-xx-300)' }}
              _focus={{ boxShadow: 'none' }}
              href={props.left.link}
              className="text-xl"
              fontWeight="500"
            >
              {props.left.text}
            </Link>
          </Flex>
          <Flex justify="end" columnGap="30px" className="text-xs">
            {props.items.map(item => {
              return (
                <Link
                  className={item[3]}
                  data-id={item[1]}
                  color="var(--sky-50)"
                  _hover={{ color: 'var(--purple-xx-300)' }}
                  _focus={{ boxShadow: 'none' }}
                  href={item[2]}
                  fontWeight="500"
                >
                  {item[0]}
                </Link>
              );
            })}
          </Flex>
        </Flex>
      </Flex>
    </ChakraProvider>
  );
}

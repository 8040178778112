import React, { useEffect } from 'react';
import NavComp from './NavComp';
import { SecondaryNavComps } from './Home';
import AboutUsMission from './AboutUsMission';
import AboutUsTeam from './AboutUsTeam';
import AboutUsCareers from './AboutUsCareers';
import CtaFooter1 from './CtaFooter1';
import FooterNew from './FooterNew';
import AboutUsContactUs from './AboutUsContactUs';
import { Helmet } from 'react-helmet';

// export const aboutUsNavItems = [
//   ['Mission', 1, '/AboutUs', 'observed3'],
//   ['Team', 2, '/AboutUs', 'observed3'],
//   ['Careers', 3, '/AboutUs', 'observed3'],
//   ['Contact Us', 4, '/AboutUs', 'observed3'],
// ];

export function AboutUs() {
  useEffect(() => {
    const intObserver1 = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        let index = entry.target.dataset['id'];
        if (
          document.querySelector(".observed3[data-id='" + index + "'") !== null
        ) {
          console.log('observed:', ".observed3[data-id='" + index + "'");
          document
            .querySelector(".observed3[data-id='" + index + "'")
            .classList.toggle('hoverOnObserve', entry.isIntersecting);
        }
      });
    });
    const observers = document.querySelectorAll('.observer3');
    if (observers !== null) {
      observers.forEach(ele => {
        intObserver1.observe(ele);
      });
    }
  });
  return (
    <div>
      <Helmet>
        <title>About ZinkML: Mission & Team</title>
        <link rel="canonical" href="https://zinkml.com/about-us" />
        <meta
          name="description"
          content="Meet our Team: Transforming Data Science | Simplifying Innovation"
        />
        <meta property="og:title" content="About ZinkML: Mission & Team" />
        <meta
          name="og:description"
          content="Meet our Team: Transforming Data Science | Simplifying Innovation"
        />
        <meta name="og:url" content="https://zinkml.com/about-us" />

        <meta property="twitter:title" content="About ZinkML: Mission & Team" />
        <meta
          name="twitter:description"
          content="Meet our Team: Transforming Data Science | Simplifying Innovation"
        />
        <meta name="twitter:url" content="https://zinkml.com/about-us" />
      </Helmet>
      <NavComp comp={SecondaryNavComps.aboutUs} />
      <AboutUsMission />
      <AboutUsTeam />
      <AboutUsCareers />
      <AboutUsContactUs />
      <CtaFooter1 />
      <FooterNew />
    </div>
  );
}

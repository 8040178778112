// import { gsap } from 'gsap';
import { Text, ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import drillDownChartGif from './drilldown-chart.gif';
import dragDrop from './drag-drop.gif';
import dragSelect from './drag-select.gif';
import modelingMadeEasy from './modeling-made-easy-1.png';
import dataIngestion from './data-ingestion.png';
import oneClickDeploy from './one-click-deploy.png';
import { AlternateHalfGridProduct } from './AlternateHalfGridProduct';
import { InProgress } from '@carbon/icons-react';
// import { ReactComponent as TestDiagram } from './TestDiagram.svg';
// import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
// import { MotionPathHelper } from 'gsap/MotionPathHelper';

// gsap.registerPlugin(MotionPathPlugin);
// gsap.registerPlugin(MotionPathHelper);

let generative = (
  <svg
    id="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    xmlSpace="preserve"
    fill="var(--slate-500)"
    width="160px"
  >
    <path class="cls-2" d="m18,20v-2h1v-7h-1v-2h4v2h-1v7h1v2h-4Z" />
    <path
      class="cls-2"
      d="m14.5005,20h2l-3.5005-11h-3l-3.4966,11h1.9988l.6018-2h4.7781l.6184,2Zm-4.7947-4l1.6284-5.4111.2559-.0024,1.6736,5.4136h-3.5579Z"
    />
    <path
      class="cls-2"
      d="m26.2171,21l-1.7871,3.621-3.9958.5806,2.8914,2.8188-.6827,3.9796,3.5741-1.879,3.5741,1.879-.6827-3.9796,2.8914-2.8186-3.9958-.5808-1.7871-3.621Z"
    />
    <polygon
      class="cls-2"
      points="17 30 0 30 0 0 30 0 30 17 28 17 28 2 2 2 2 28 17 28 17 30"
    />
  </svg>
);

export const benefits = [
  {
    heading: 'One Click Ingestion',
    color: 'var(--orange-xx-300)',
    bgColor: 'var(--orange-xx-900)',
    link: 'connect',
    subItems: [
      {
        subHeading: 'Pre-built cloud connectors',
        paraText: (
          <Text>
            Easily connect data from all major cloud providers -&nbsp;
            <span style={{ color: 'var(--slate-300)' }}>
              AWS, GCP, Azure, Cloudfare, ...
            </span>
          </Text>
        ),
        subImage: dataIngestion,
        // subImage: <simpleGraph />,
        color: 'red',
      },
      {
        subHeading: 'URLs and Local files',
        paraText: 'Quickly upload files from web and local machine.',
        subImage: dataIngestion,
        // subImage: <simpleGraph />,
        color: 'red',
      },
      {
        subHeading: 'Wide variety of file formats',
        paraText: (
          <Text>
            Supports all table data formats -&nbsp;
            <span style={{ color: 'var(--slate-300)' }}>
              Excel, Csv, Tsv, OpenOffice, Apache Parquet, Json, NdJson, ...
            </span>
          </Text>
        ),
        subImage: dataIngestion,
        // subImage: <simpleGraph />,
        color: 'red',
      },
      // {
      //   subHeading: 'Data validation',
      //   paraText:
      //     'Ensure data quality and integrity with in-built validation checks.',
      //   subImage: connectors,
      //   // subImage: <simpleGraph />,
      //   color: 'red',
      // },
    ],
  },
  {
    heading: 'Unmatched Data Cleaning',
    color: '#0c9d46',
    link: 'clean1',
    subItems: [
      {
        subHeading: 'Comprehensive and intuitive',
        paraText:
          'Easily identify anomalies and quality issues in your data and fix them. ',
        subImage: dragDrop,
        // subSvg: aisvg,
        color: '#bae6fdb0',
      },
      // {
      //   subHeading: 'Save time and effort',
      //   paraText:
      //     'Quickly configure and run data cleaning operations with in-built, intuitive data cleaning tools. Easily identify data quality issues and anamolies with automated charts and tables.',
      //   subImage: dragDrop,
      //   // subSvg: aisvg,
      //   color: '#bae6fdb0',
      // },
      {
        subHeading: 'Automate away',
        paraText:
          'Templates for repetitive data cleaning tasks like dealing with nulls and nans, missing value imputation, outlier detection, and standardization.',
        subImage: dragDrop,
        // subSvg: aisvg,
        color: '#bae6fdb0',
      },
      // {
      //   subHeading: 'Data profiling',
      //   paraText:
      //     'Generate data profiles and statistics to gain insights into the dataset, including summary statistics, data distributions, and data quality metrics.',
      //   subImage: dragDrop,
      //   // subSvg: aisvg,
      //   color: '#bae6fdb0',
      // },
    ],
  },
  {
    heading: 'Accelerate Data Processing',
    color: '#0c9d46',
    link: 'transform1',
    subItems: [
      {
        subHeading: 'Visual workflow builder',
        paraText:
          'Simpler drawing interface allows you to iteratively build and execute complicated data workflows in a matter of minutes.',
        subImage: dragSelect,
        // subSvg: accelerate,
        color: '#bae6fdb0',
      },
      {
        subHeading: 'Extensive transformations',
        paraText:
          'Perform data transformation tasks like filter, join, aggregation, and reshape using a drag-and-drop interface. Everything you can do with pandas and more. ',
        subImage: dragSelect,
        // subSvg: accelerate,
        color: '#bae6fdb0',
      },
      // {
      //   subHeading: 'Transformations',
      //   paraText:
      //     'Perform data transformation tasks like filtering, joining, aggregating, and reshaping data using a drag-and-drop interface.',
      //   subImage: dragSelect,
      //   // subSvg: accelerate,
      //   color: '#bae6fdb0',
      // },
      {
        subHeading: 'Workflow automation',
        paraText:
          'Templates to automate away data preparation tasks. Easily monitor and track their progress.',
        subImage: dragSelect,
        // subSvg: accelerate,
        color: '#bae6fdb0',
      },
    ],
  },
  {
    heading: 'Modeling Made Easy',
    color: '#0c9d46',
    link: 'model1',
    subItems: [
      {
        subHeading: 'Faster experimentation',
        paraText:
          'Quickly prototype and iterate on predictive models without the need to write complex code, accelerating the model development.',
        // subImage: autoCompletion,
        subImage: modelingMadeEasy,
        color: '#bae6fdb0',
      },
      {
        subHeading: 'Best model selection',
        paraText: (
          <Text>
            Access a wide range of machine learning algorithms and frameworks,
            including Scikit-learn, XGBoost, and PyTorch
            <sup>
              <InProgress
                style={{ display: 'inline' }}
                size="8px"
                fill="var(--gray-400)"
              />
            </sup>
            .
          </Text>
        ),
        // subImage: autoCompletion,
        subImage: modelingMadeEasy,
        color: '#bae6fdb0',
      },
      {
        subHeading: 'Customizable to the core',
        paraText:
          'Customize every aspect of the model, including hyperparameters, layers, and optimization criteria, to build the best model for your data.',
        // subImage: autoCompletion,
        subImage: modelingMadeEasy,
        color: '#bae6fdb0',
      },
      {
        subHeading: 'Validation and evaluation',
        paraText:
          "Easily monitor and track all aspects of a model's performance using interactive charts and tables. ",
        // subImage: autoCompletion,
        subImage: modelingMadeEasy,
        color: '#bae6fdb0',
      },
    ],
  },

  {
    heading: 'Visual Analytics',
    color: '#0c9d46',
    link: 'visualize1',
    subItems: [
      {
        subHeading: 'Deep integration',
        paraText:
          'Access tables and charts at every step of the transformation.',
        subImage: drillDownChartGif,
        // subSvg: visual,
        // subVideo: drillDownChart,
        color: '#bae6fdb0',
      },
      {
        subHeading: 'Smart charts',
        paraText:
          'Gain insights, and identify patterns in data using smart charts with tools such as zooming, and filtering to drill down into specific data points.',
        subImage: drillDownChartGif,
        // subSvg: visual,
        // subVideo: drillDownChart,
        color: '#bae6fdb0',
      },
      {
        subHeading: 'Table for ideas',
        paraText:
          'Interactively explore and analyze data using a Table interface with features like sorting, selection, and advanced filtering with fuzzy-search, regex and expressions.',
        subImage: drillDownChartGif,
        // subSvg: visual,
        // subVideo: drillDownChart,
        color: '#bae6fdb0',
      },
    ],
  },
  {
    heading: 'One-click Deployment',
    color: '#0c9d46',
    link: 'deploy1',
    subItems: [
      {
        subHeading: 'Faster time to value',
        paraText:
          'Transition from model development to deployment with a single click. Deploy models as REST APIs or batch jobs. Realize value from data science projects faster with streamlined deployment.',
        // subImage: oneClickDeployment,
        subImage: oneClickDeploy,
        color: '#bae6fdb0',
      },
      {
        subHeading: 'Version control',
        paraText:
          'Manage and track different versions of models and deployed data workflows.',
        // subImage: oneClickDeployment,
        subImage: oneClickDeploy,
        color: '#bae6fdb0',
      },
    ],
  },
  {
    heading: (
      <Text
        fontSize={['1.8rem', '2.25rem', '2.25rem', '2.25rem', '2.25rem']}
        lineHeight={['2.0rem', '2.5rem', '2.5rem', '2.5rem', '2.5rem']}
      >
        Generative AI at Scale
        <sup>
          <InProgress
            style={{ display: 'inline' }}
            size="16px"
            fill="var(--gray-400)"
          />
        </sup>
      </Text>
    ),
    color: '#0c9d46',
    link: 'generative1',
    subItems: [
      {
        subHeading: 'Next best operator',
        paraText:
          'Use the platform-recommended next best operator at each point of data pipeline so that you are never out of ideas on how to better the value from your data.',
        // subImage: simpleGraph,
        subSvg: generative,
        color: '#bae6fdb0',
      },
      {
        subHeading: 'Templates for use cases',
        paraText:
          'Generate data pipeline templates for common use cases, such as churn prediction, customer segmentation, and fraud detection, to accelerate the model development process.',
        // subImage: simpleGraph,
        subSvg: generative,
        color: '#bae6fdb0',
      },
      // {
      //   subHeading: 'Just Prompt to Create',
      //   paraText:
      //     'Just type in your use case and the platform will automatically create a fully customizable data pipeline, along with processing workflow and models, best-suited for your use case.',
      //   // subImage: simpleGraph,
      //   subSvg: generative,
      //   color: '#bae6fdb0',
      // },
    ],
  },
  // {
  //   heading: 'No Coding Required',
  //   color: 'var(--red-xx-300)',
  //   bgColor: 'var(--red-xx-900)',
  //   link: '/Product',
  //   subItems: [
  //     {
  //       subHeading: 'No coding required for even complex tasks',
  //       paraText: 'No coding required for even complex tasks',
  //       subImage: epiWebImagesConnect,
  //       color: '#bae6fdb0',
  //     },
  //   ],
  // },
  // {
  //   heading: 'Easy to Learn & Use',
  //   color: '#bae6fdb0',
  //   link: '/Product',
  //   subItems: [
  //     {
  //       subHeading: 'Easy to learn, use, explain',
  //       paraText:
  //         'As easy as working on Excel - almost everything can be achieved through clicks. No need of Access, SQL or coding - just statistics and domain knowledge to take full advantage of AI and ML. Standard, well explained terminology throughout the system. Easy to install and kickstart. Helpful, easy-to-follow hints at each step. ',
  //       subImage: epiWebImagesConnect,
  //       color: '#bae6fdb0',
  //     },
  //     {
  //       subHeading: 'Find the operators you need',
  //       paraText:
  //         'Meaningful bucketing of operations. Intuitive search helps find the needed operators quickly. Turn on the next-best-step to guide you through the data pipeline.',
  //       subImage: epiWebImagesConnect,
  //       color: '#bae6fdb0',
  //     },
  //     {
  //       subHeading: 'Catch and resolve errors quickly',
  //       paraText:
  //         'Carefully written error messages help resolve errors quickly.',
  //       subImage: epiWebImagesConnect,
  //       color: '#bae6fdb0',
  //     },
  //   ],
  // },
  // {
  //   heading: 'Automated yet Flexible',
  //   color: '#8d1287',
  //   link: '/Product',
  //   subItems: [
  //     {
  //       subHeading: 'A low code platform for data scientists',
  //       paraText:
  //         'Take advantage of AI & ML through clicks. Do not need coding for even for the complex tasks. Saves significant time and effort by automating data engineering.Save, reuse and share your data pipeline anywhere, anytime. Smart charts and tables at all inputs and outputs.',
  //       subImage: epiWebImagesConnect,
  //     },
  //     {
  //       subHeading: 'Puts no limit to applying your knowledge',
  //       paraText:
  //         'Every tool and operator on the platform is customizable so that you are not limited by the platform in any way. Advanced users can even add their own features to the platform in few, simple steps - the platform autogenerates the required code for front-end.',
  //       subImage: epiWebImagesConnect,
  //     },
  //     {
  //       subHeading: 'Optimize and find the best model',
  //       paraText:
  //         'Every aspect of modelling can be tweaked - hyper-parameters, layers, initiation, optimimization criteria, folds, grids - so that you experiment and find the best possible solution.',
  //       subImage: epiWebImagesConnect,
  //     },
  //   ],
  // },
  // {
  //   heading: 'Tons of Data Engineering Tools',
  //   color: 'var(--sky-300)',
  //   link: '/Product',
  //   subItems: [
  //     {
  //       subHeading: 'Shorten your time to clean data',
  //       paraText:
  //         '80% time and effort of all data science projects goes towards getting the data in a proper format. Our platform automates data evaluation and cleaning. Consists all data processing options from popular dataframes such as pandas, pyspark etc. Advanced options to handle outliers and missing data. ',
  //       subImage: epiWebImagesConnect,
  //       color: '#bae6fdb0',
  //     },
  //     {
  //       subHeading: 'Basic Statistics through tables and charts',
  //       paraText:
  //         'Supplements the tools with smart tables and charts at the start and end of each operator. You see the data, and all its parameters, as it changes - just as in excel. Customize these charts as needed. Deep-dive into data through interactivity and drill-down functionality in charts.',
  //       subImage: epiWebImagesConnect,
  //       color: '#bae6fdb0',
  //     },
  //   ],
  // },
  // {
  //   heading: 'Diverse set of Predictive Models',
  //   color: 'var(--green-xx-300)',
  //   link: '/Product',
  //   subItems: [
  //     {
  //       subHeading: 'All the predictive power you will ever need',
  //       paraText:
  //         'Rich set of predictive models - including Tree based models, Probailistic models, Neural Network Models, and Linear models. Moreover, the set keeps evolving and growing with advances in machine learning algorithms. Everything about the models is customizable - a must-to-have feature if you need to modify ML models.',
  //       subImage: epiWebImagesConnect,
  //       color: '#bae6fdb0',
  //     },
  //     {
  //       subHeading: 'Apply smart, not brute, force',
  //       paraText:
  //         'Run only the more appropriate models suitable for the dataset and prediction objective, rather than run every model draining out the available computing power. The smart recommendation system helps identify the more appropriate models for the dataset.',
  //       subImage: epiWebImagesConnect,
  //       color: '#bae6fdb0',
  //     },
  //     {
  //       subHeading: 'Complete transparency, better explainability',
  //       paraText:
  //         'Access all parameters, logs and results of each model. Save, reuse, share and deploy models anytime, anywhere. We use common, easily understandable terms throughout the system. Explanations for technical terms pop-up on hovering over them.',
  //       subImage: epiWebImagesConnect,
  //       color: '#bae6fdb0',
  //     },
  //   ],
  // },
  // {
  //   heading: 'Click to Deploy',
  //   color: 'var(--amber-400)',
  //   link: '/Product',
  //   subItems: [
  //     {
  //       subHeading: 'Requires just few clicks to deploy any pipeline',
  //       paraText:
  //         'Easy and quick deployment of your workflow, data pipeline or any model - just a few clicks needed.',
  //       subImage: epiWebImagesConnect,
  //       color: '#bae6fdb0',
  //     },
  //     {
  //       subHeading: 'API endpoint for every model',
  //       paraText: 'Easily integrable to existing systems through API access.',
  //       subImage: epiWebImagesConnect,
  //       color: '#bae6fdb0',
  //     },
  //     {
  //       subHeading: 'Monitor your deployments',
  //       paraText:
  //         'Monitor the performance of your deployments continuously. Logs, measures and compares performance continuously - preventing model drift and catching errors.',
  //       subImage: epiWebImagesConnect,
  //       color: '#bae6fdb0',
  //     },
  //   ],
  // },
  // {
  //   heading: 'Real Time Collaboration',
  //   color: '#e66702',
  //   link: '/Product',
  //   subItems: [
  //     {
  //       subHeading: 'Simple, intuitive project-sharing among stakeholders',
  //       paraText:
  //         'Work together on the same project at the same time - sharing suggestions and editing the project in real time by multiple users. Divide big project in small parts between users. Hassle-free collaboration with clearly defined roles for users.  No more ping-pong of files to share and make changes in the project.',
  //       subImage: epiWebImagesConnect,
  //       color: '#bae6fdb0',
  //     },
  //     {
  //       subHeading: 'Share and implement feedback without delay',
  //       paraText:
  //         'With real time collaboration, multiple users can edit the project and see the changes in real time. Removes the delay between getting and implementing feedback.',
  //       subImage: epiWebImagesConnect,
  //       color: '#bae6fdb0',
  //     },
  //   ],
  // },

  // {
  //   heading: 'Simple, Fair pricing',
  //   color: 'var(--emerald-300)',
  //   link: '/Product',
  //   subItems: [
  //     {
  //       subHeading: 'No complicated calculus in pricing',
  //       paraText:
  //         'Simple, straightforward pricing. No surprises. Every care has been taken to ensure that the pricing is fair and justified and you get sizable return for your investment in our product.',
  //       subImage: epiWebImagesConnect,
  //       color: '#bae6fdb0',
  //     },
  //   ],
  // },
  // {
  //   heading: 'Managed Infrastructure',
  //   color: '#099b9d',
  //   link: '/Product',
  //   subItems: [
  //     {
  //       subHeading: 'Never get short on computing power',
  //       paraText:
  //         'Add projects, users, and workflow without fear of getting short on infrastructure. All processing happens on cloud - not on local system. And since it is autoscalable, the platform adjusts the processing power as and when required. Easy to run compute intensive models without getting stuck or waiting for other resources to complete task.',
  //       subImage: epiWebImagesConnect,
  //       color: '#bae6fdb0',
  //     },
  //     {
  //       subHeading: 'Autoscalable infrastructure',
  //       paraText:
  //         'The platform adjusts the processing power as and when required. Easy to run compute intensive models without getting stuck or waiting for other resources to complete task.',
  //       subImage: epiWebImagesConnect,
  //       color: '#bae6fdb0',
  //     },
  //   ],
  // },
  // {
  //   heading: 'Support & Documentation',
  //   color: '#039e8f',
  //   link: '/Product',
  //   subItems: [
  //     {
  //       subHeading: 'Extensive, well-explained documentation',
  //       paraText:
  //         'The platform is self-explanatory and easy to learn. A stable, robust design stimulates you to play and learn the capabilities of the platform on the go. On top of that, the extensive documentation ensures that no technical term goes unexplained. Everything you may need to use the platform has been meticulously documented',
  //       subImage: epiWebImagesConnect,
  //       color: '#bae6fdb0',
  //     },
  //     {
  //       subHeading: 'Proactive Support',
  //       paraText:
  //         'We provide free support for the first 30 days to all users - including the non-paying individual users. We constantly take feedback from our diverse set of users and support them with constant updates. The model set gets updated every quarter to account for new algorithms.',
  //       subImage: epiWebImagesConnect,
  //       color: '#bae6fdb0',
  //     },
  //   ],
  // },
];

export default function HomeProductNew() {
  /* useEffect(() => {
    gsap.registerPlugin(MotionPathPlugin);
    gsap.to('.dataStoreBox', {
      duration: 5,
      ease: 'power1.inOut',
      immediateRender: true,
      motionPath: {
        path: '#dataStoreBoxArrow',
        align: '#dataStoreBoxArrow',
        // alignOrigin: [0.5, 0.5],
        // autoRotate: 90,
      },
      scale: 0.0,
      repeat: -1,
      yoyo: true,
    });
    return;
  }); */
  return (
    <ChakraProvider>
      <AlternateHalfGridProduct
        benefits={benefits}
        heading="Discover Our Product"
      />
    </ChakraProvider>
  );
}

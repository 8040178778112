// import { gsap } from 'gsap';
import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import { AlternateHalfGrid } from './AlternateHalfGrid';
import simpleGraph from './SimpleGraph.png';
// import ideate from './ideate.svg';
import instantFeedback from './instant-feedback.png';
import experimentation from './experimentation.png';
import { DataEnrichment } from '@carbon/icons-react';
import seamlessTeamwork from './seamless-teamwork.gif';
// import { ReactComponent as TestDiagram } from './TestDiagram.svg';
// import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
// import { MotionPathHelper } from 'gsap/MotionPathHelper';

// gsap.registerPlugin(MotionPathPlugin);
// gsap.registerPlugin(MotionPathHelper);
let learnIcon = <DataEnrichment fill="var(--slate-300)" />;

let transparencySvg = (
  <svg
    id="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    xmlSpace="preserve"
    fill="var(--slate-500)"
    width="160px"
  >
    <defs></defs>
    <title>collaborate</title>
    <rect
      x="20"
      y="18"
      width="2"
      height="2"
      fill="var(--slate-300)"
      opacity="0.7"
    />
    <rect
      x="28"
      y="16"
      width="2"
      height="2"
      fill="var(--slate-300)"
      opacity="0.7"
    />
    <rect
      x="14"
      y="6"
      width="2"
      height="2"
      fill="var(--slate-300)"
      opacity="0.7"
    />
    <path
      d="M16,22H12V16a2.0023,2.0023,0,0,0-2-2H4a2.0023,2.0023,0,0,0-2,2v6a2.0023,2.0023,0,0,0,2,2h6v4a2.0023,2.0023,0,0,0,2,2h4a2.0023,2.0023,0,0,0,2-2V24A2.0023,2.0023,0,0,0,16,22ZM4,22V16h6v6Zm8,6V24h4v4Z"
      fill="var(--pink-300)"
      opacity="0.7"
    />
    <path
      d="M28,30H24a2.0021,2.0021,0,0,1-2-2V24a2.0021,2.0021,0,0,1,2-2h4a2.0021,2.0021,0,0,1,2,2v4A2.0021,2.0021,0,0,1,28,30Zm-4-6v4h4V24Z"
      fill="var(--teal-200)"
      opacity="0.7"
    />
    <path
      d="M28,2H22a2.0023,2.0023,0,0,0-2,2v6H18a2.0023,2.0023,0,0,0-2,2v2a2.0023,2.0023,0,0,0,2,2h2a2.0023,2.0023,0,0,0,2-2V12h6a2.0023,2.0023,0,0,0,2-2V4A2.0023,2.0023,0,0,0,28,2ZM18,14V12h2v2Zm4-4V4h6v6Z"
      fill="var(--orange-200)"
      opacity="0.7"
    />
    <path
      d="M8,10H4A2.0021,2.0021,0,0,1,2,8V4A2.0021,2.0021,0,0,1,4,2H8a2.0021,2.0021,0,0,1,2,2V8A2.0021,2.0021,0,0,1,8,10ZM4,4V8H8V4Z"
      fill="var(--teal-200)"
      opacity="0.7"
    />
  </svg>
);

export const benefits = [
  {
    heading: 'Zero Code Without Compromise',
    color: 'var(--orange-xx-300)',
    bgColor: 'var(--orange-xx-900)',
    link: '/#ProductComponents',
    linkText: 'Learn More',
    linkIcon: learnIcon,
    subItems: [
      {
        subHeading: 'Drag and Drop',
        paraText:
          'Create data workflows as interconnected graphs as easily as snapping together lego blocks.',
        subImage: simpleGraph,
        color: 'red',
      },
    ],
  },
  {
    heading: 'Perfected for Experimentation',
    color: '#0c9d46',
    link: '/#ProductComponents',
    linkText: 'Learn More',
    linkIcon: learnIcon,
    svgClass: 'ideateSvg',
    subItems: [
      {
        subHeading: 'Plug and Play',
        paraText:
          'Quickly prototype solutions using our comprehensive library of processing blocks to iterate through your ideas at a faster pace.',
        // subImage: ideate,
        color: '#bae6fdb0',
        subImage: experimentation,
      },
    ],
  },

  {
    heading: 'Instant Feedback',
    color: '#0c9d46',
    link: '/#ProductComponents',
    linkText: 'Learn More',
    linkIcon: learnIcon,
    subItems: [
      {
        subHeading: 'Realtime validation checks',
        paraText:
          'Error-free data workflows with instant validation of inputs.',
        subImage: instantFeedback,
        color: '#bae6fdb0',
      },
      {
        subHeading: 'Auto completions',
        paraText:
          'Create data pipelines with realtime prompts by system, boosting productivity and reducing errors.',
        subImage: instantFeedback,
        color: '#bae6fdb0',
      },
    ],
  },
  {
    heading: 'Seamless Teamwork',
    color: '#0c9d46',
    link: '/#ProductComponents',
    linkText: 'Learn More',
    linkIcon: learnIcon,
    subItems: [
      {
        subHeading: 'Realtime collaboration',
        paraText:
          'Co-develop the project in realtime, break big projects in small chunks and divide work among team members.',
        // subImage: simpleGraph,
        subImage: seamlessTeamwork,
        color: '#bae6fdb0',
      },
      {
        subHeading: 'Anytime, anywhere',
        paraText:
          'Access your work anytime from anywhere with browser based access to the platform.',
        // subImage: simpleGraph,
        subImage: seamlessTeamwork,
        color: '#bae6fdb0',
      },
      // {
      //   subHeading: 'Ideas uninterrupted',
      //   paraText:
      //     'Use in-built chat system to communicate instantly with your team members.',
      //   // subImage: simpleGraph,
      //   subSvg: seamless,
      //   color: '#bae6fdb0',
      // },
    ],
  },
  {
    heading: 'Enhanced Transparency',
    color: '#0c9d46',
    link: '/#ProductComponents',
    linkText: 'Learn More',
    linkIcon: learnIcon,
    subItems: [
      {
        subHeading: 'Bridged communication gaps',
        paraText:
          'Replace code with simple graphs, enabling even non-technical stakeholders to contribute to data science projects.',
        // subImage: simpleGraph,
        subSvg: transparencySvg,
        color: '#bae6fdb0',
      },
      {
        subHeading: 'Cross-domain expertise',
        paraText:
          'Leverage capabilities of Subject Matter Experts without being hindered by coding barriers.',
        // subImage: simpleGraph,
        subSvg: transparencySvg,
        color: '#bae6fdb0',
      },
      // {
      //   subHeading: 'Enhanced Transparency',
      //   paraText:
      //     "With a visual interface, everyone can review, validate and track the project's progress.",
      //   // subImage: simpleGraph,
      //   subSvg: animatedSvg,
      //   color: '#bae6fdb0',
      // },
    ],
  },
  // {
  //   heading: 'No Coding Required',
  //   color: 'var(--red-xx-300)',
  //   bgColor: 'var(--red-xx-900)',
  //   link: '/Product',
  //   subItems: [
  //     {
  //       subHeading: 'No coding required for even complex tasks',
  //       paraText: 'No coding required for even complex tasks',
  //       subImage: epiWebImagesConnect,
  //       color: '#bae6fdb0',
  //     },
  //   ],
  // },
  // {
  //   heading: 'Easy to Learn & Use',
  //   color: '#bae6fdb0',
  //   link: '/Product',
  //   subItems: [
  //     {
  //       subHeading: 'Easy to learn, use, explain',
  //       paraText:
  //         'As easy as working on Excel - almost everything can be achieved through clicks. No need of Access, SQL or coding - just statistics and domain knowledge to take full advantage of AI and ML. Standard, well explained terminology throughout the system. Easy to install and kickstart. Helpful, easy-to-follow hints at each step. ',
  //       subImage: epiWebImagesConnect,
  //       color: '#bae6fdb0',
  //     },
  //     {
  //       subHeading: 'Find the operators you need',
  //       paraText:
  //         'Meaningful bucketing of operations. Intuitive search helps find the needed operators quickly. Turn on the next-best-step to guide you through the data pipeline.',
  //       subImage: epiWebImagesConnect,
  //       color: '#bae6fdb0',
  //     },
  //     {
  //       subHeading: 'Catch and resolve errors quickly',
  //       paraText:
  //         'Carefully written error messages help resolve errors quickly.',
  //       subImage: epiWebImagesConnect,
  //       color: '#bae6fdb0',
  //     },
  //   ],
  // },
  // {
  //   heading: 'Automated yet Flexible',
  //   color: '#8d1287',
  //   link: '/Product',
  //   subItems: [
  //     {
  //       subHeading: 'A low code platform for data scientists',
  //       paraText:
  //         'Take advantage of AI & ML through clicks. Do not need coding for even for the complex tasks. Saves significant time and effort by automating data engineering.Save, reuse and share your data pipeline anywhere, anytime. Smart charts and tables at all inputs and outputs.',
  //       subImage: epiWebImagesConnect,
  //     },
  //     {
  //       subHeading: 'Puts no limit to applying your knowledge',
  //       paraText:
  //         'Every tool and operator on the platform is customizable so that you are not limited by the platform in any way. Advanced users can even add their own features to the platform in few, simple steps - the platform autogenerates the required code for front-end.',
  //       subImage: epiWebImagesConnect,
  //     },
  //     {
  //       subHeading: 'Optimize and find the best model',
  //       paraText:
  //         'Every aspect of modelling can be tweaked - hyper-parameters, layers, initiation, optimimization criteria, folds, grids - so that you experiment and find the best possible solution.',
  //       subImage: epiWebImagesConnect,
  //     },
  //   ],
  // },
  // {
  //   heading: 'Tons of Data Engineering Tools',
  //   color: 'var(--sky-300)',
  //   link: '/Product',
  //   subItems: [
  //     {
  //       subHeading: 'Shorten your time to clean data',
  //       paraText:
  //         '80% time and effort of all data science projects goes towards getting the data in a proper format. Our platform automates data evaluation and cleaning. Consists all data processing options from popular dataframes such as pandas, pyspark etc. Advanced options to handle outliers and missing data. ',
  //       subImage: epiWebImagesConnect,
  //       color: '#bae6fdb0',
  //     },
  //     {
  //       subHeading: 'Basic Statistics through tables and charts',
  //       paraText:
  //         'Supplements the tools with smart tables and charts at the start and end of each operator. You see the data, and all its parameters, as it changes - just as in excel. Customize these charts as needed. Deep-dive into data through interactivity and drill-down functionality in charts.',
  //       subImage: epiWebImagesConnect,
  //       color: '#bae6fdb0',
  //     },
  //   ],
  // },
  // {
  //   heading: 'Diverse set of Predictive Models',
  //   color: 'var(--green-xx-300)',
  //   link: '/Product',
  //   subItems: [
  //     {
  //       subHeading: 'All the predictive power you will ever need',
  //       paraText:
  //         'Rich set of predictive models - including Tree based models, Probailistic models, Neural Network Models, and Linear models. Moreover, the set keeps evolving and growing with advances in machine learning algorithms. Everything about the models is customizable - a must-to-have feature if you need to modify ML models.',
  //       subImage: epiWebImagesConnect,
  //       color: '#bae6fdb0',
  //     },
  //     {
  //       subHeading: 'Apply smart, not brute, force',
  //       paraText:
  //         'Run only the more appropriate models suitable for the dataset and prediction objective, rather than run every model draining out the available computing power. The smart recommendation system helps identify the more appropriate models for the dataset.',
  //       subImage: epiWebImagesConnect,
  //       color: '#bae6fdb0',
  //     },
  //     {
  //       subHeading: 'Complete transparency, better explainability',
  //       paraText:
  //         'Access all parameters, logs and results of each model. Save, reuse, share and deploy models anytime, anywhere. We use common, easily understandable terms throughout the system. Explanations for technical terms pop-up on hovering over them.',
  //       subImage: epiWebImagesConnect,
  //       color: '#bae6fdb0',
  //     },
  //   ],
  // },
  // {
  //   heading: 'Click to Deploy',
  //   color: 'var(--amber-400)',
  //   link: '/Product',
  //   subItems: [
  //     {
  //       subHeading: 'Requires just few clicks to deploy any pipeline',
  //       paraText:
  //         'Easy and quick deployment of your workflow, data pipeline or any model - just a few clicks needed.',
  //       subImage: epiWebImagesConnect,
  //       color: '#bae6fdb0',
  //     },
  //     {
  //       subHeading: 'API endpoint for every model',
  //       paraText: 'Easily integrable to existing systems through API access.',
  //       subImage: epiWebImagesConnect,
  //       color: '#bae6fdb0',
  //     },
  //     {
  //       subHeading: 'Monitor your deployments',
  //       paraText:
  //         'Monitor the performance of your deployments continuously. Logs, measures and compares performance continuously - preventing model drift and catching errors.',
  //       subImage: epiWebImagesConnect,
  //       color: '#bae6fdb0',
  //     },
  //   ],
  // },
  // {
  //   heading: 'Real Time Collaboration',
  //   color: '#e66702',
  //   link: '/Product',
  //   subItems: [
  //     {
  //       subHeading: 'Simple, intuitive project-sharing among stakeholders',
  //       paraText:
  //         'Work together on the same project at the same time - sharing suggestions and editing the project in real time by multiple users. Divide big project in small parts between users. Hassle-free collaboration with clearly defined roles for users.  No more ping-pong of files to share and make changes in the project.',
  //       subImage: epiWebImagesConnect,
  //       color: '#bae6fdb0',
  //     },
  //     {
  //       subHeading: 'Share and implement feedback without delay',
  //       paraText:
  //         'With real time collaboration, multiple users can edit the project and see the changes in real time. Removes the delay between getting and implementing feedback.',
  //       subImage: epiWebImagesConnect,
  //       color: '#bae6fdb0',
  //     },
  //   ],
  // },

  // {
  //   heading: 'Simple, Fair pricing',
  //   color: 'var(--emerald-300)',
  //   link: '/Product',
  //   subItems: [
  //     {
  //       subHeading: 'No complicated calculus in pricing',
  //       paraText:
  //         'Simple, straightforward pricing. No surprises. Every care has been taken to ensure that the pricing is fair and justified and you get sizable return for your investment in our product.',
  //       subImage: epiWebImagesConnect,
  //       color: '#bae6fdb0',
  //     },
  //   ],
  // },
  // {
  //   heading: 'Managed Infrastructure',
  //   color: '#099b9d',
  //   link: '/Product',
  //   subItems: [
  //     {
  //       subHeading: 'Never get short on computing power',
  //       paraText:
  //         'Add projects, users, and workflow without fear of getting short on infrastructure. All processing happens on cloud - not on local system. And since it is autoscalable, the platform adjusts the processing power as and when required. Easy to run compute intensive models without getting stuck or waiting for other resources to complete task.',
  //       subImage: epiWebImagesConnect,
  //       color: '#bae6fdb0',
  //     },
  //     {
  //       subHeading: 'Autoscalable infrastructure',
  //       paraText:
  //         'The platform adjusts the processing power as and when required. Easy to run compute intensive models without getting stuck or waiting for other resources to complete task.',
  //       subImage: epiWebImagesConnect,
  //       color: '#bae6fdb0',
  //     },
  //   ],
  // },
  // {
  //   heading: 'Support & Documentation',
  //   color: '#039e8f',
  //   link: '/Product',
  //   subItems: [
  //     {
  //       subHeading: 'Extensive, well-explained documentation',
  //       paraText:
  //         'The platform is self-explanatory and easy to learn. A stable, robust design stimulates you to play and learn the capabilities of the platform on the go. On top of that, the extensive documentation ensures that no technical term goes unexplained. Everything you may need to use the platform has been meticulously documented',
  //       subImage: epiWebImagesConnect,
  //       color: '#bae6fdb0',
  //     },
  //     {
  //       subHeading: 'Proactive Support',
  //       paraText:
  //         'We provide free support for the first 30 days to all users - including the non-paying individual users. We constantly take feedback from our diverse set of users and support them with constant updates. The model set gets updated every quarter to account for new algorithms.',
  //       subImage: epiWebImagesConnect,
  //       color: '#bae6fdb0',
  //     },
  //   ],
  // },
];

export default function HomeUSPNew() {
  /* useEffect(() => {
    gsap.registerPlugin(MotionPathPlugin);
    gsap.to('.dataStoreBox', {
      duration: 5,
      ease: 'power1.inOut',
      immediateRender: true,
      motionPath: {
        path: '#dataStoreBoxArrow',
        align: '#dataStoreBoxArrow',
        // alignOrigin: [0.5, 0.5],
        // autoRotate: 90,
      },
      scale: 0.0,
      repeat: -1,
      yoyo: true,
    });
    return;
  }); */
  return (
    <ChakraProvider>
      <AlternateHalfGrid
        benefits={benefits}
        heading="Discover the Edge with Us"
      />
    </ChakraProvider>
  );
}

import { ChakraProvider, Flex, Text, Box, Link, Grid } from '@chakra-ui/react';
import react from 'react';
import NavComp from './NavComp';
import FooterNew from './FooterNew';
import CtaFooter1 from './CtaFooter1';
import { Helmet } from 'react-helmet';
// import './Styles.css';

export default function TermsOfUse() {
  return (
    <ChakraProvider>
      <Helmet>
        <title>ZinkML: Terms of Use</title>
        <link rel="canonical" href="https://zinkml.com/terms-of-use" />
        <meta
          name="description"
          content="Please see ZinkML's Terms of Use here. If you have any questions regarding this information, please feel free to contact us at any time."
        />
        <meta property="og:title" content="ZinkML: Terms of Use" />
        <meta
          name="og:description"
          content="Please see ZinkML's Terms of Use here. If you have any questions regarding this information, please feel free to contact us at any time."
        />
        <meta name="og:url" content="https://zinkml.com/terms-of-use" />

        <meta property="twitter:title" content="ZinkML: Terms of Use" />
        <meta
          name="twitter:description"
          content="Please see ZinkML's Terms of Use here. If you have any questions regarding this information, please feel free to contact us at any time."
        />
        <meta name="twitter:url" content="https://zinkml.com/terms-of-use" />
      </Helmet>
      <NavComp />
      <Flex
        id="homeTop"
        width="100vw"
        justify="center"
        paddingY="7rem"
        // bg="#f5f5f7"
        // color="#1d1d1f"
        flexDir="column"
        gap="2rem"
      >
        <Text
          // fontSize={['40px', '40px', '50px', '60px', '60px']}
          lineHeight="1.35"
          className="text-5xl"
          fontWeight="600"
          letterSpacing="-.015em"
          bgGradient="linear-gradient(145deg, var(--amber-300), var(--purple-xx-300))"
          bgClip="text"
          transition="all ease-in-out 300ms"
          textAlign="center"
          _hover={{
            bgGradient:
              'linear-gradient(145deg, var(--amber-400), var(--purple-xx-400))',
            transform: 'translatez(10px) scale(1.02)',
            opacity: '1',
          }}
        >
          Terms of Use
        </Text>
        <Flex
          width="calc(min(1080px, 96%))"
          direction="column"
          margin="0 auto"
          gap="4rem"
        >
          <Grid
            templateColumns="repeat(1,minmax(0,1fr))"
            gap="0"
            // paddingY="2rem"
            // paddingX="5"
            padding="0"
            width="100%"
            boxShadow="0 0 0 1px #00000020, 0 9px 9px #0000000f, 0 7px 7px #0000000d, 0 5px 4px #0000000a, 0 3px 3px #00000008, 0 2px 2px #00000005"
            borderTop="1px solid var(--slate-800)"
            borderRadius="var(--round-xxl)"
            overflow="hidden"
          >
            <Flex
              gridColumn={['span 12', 'span 12', 'span 9']}
              direction="column"
              justify="center"
              // paddingX="5"
              // paddingY="10"
              padding="3rem 1rem 3rem 2rem"
              gap="5px"
              // maxWidth="450px"
              bg="#1a1e1f"
            >
              <Text className="para-text">Last Updated: 20th April, 2024</Text>
              <Text className="para-text">
                Welcome to ZinkML Technologies Private Limited Company, ("we,"
                "our," or "us"). These Terms of Use govern your access to and
                use of our website, products, and services, which are used by
                consumers all over the world. Please read these Terms of Use
                carefully before using our website, products, or services.
              </Text>
              <Text className="para-text">
                By accessing or using our website, products, or services, you
                acknowledge that you have read, understood, and agree to be
                bound by these Terms of Use. If you do not agree with these
                terms, please do not use our website, products, or services.
              </Text>
              <Text
                className="text-lg"
                lineHeight="1.5rem"
                // fontSize={['1.875rem', '2.25rem', '3rem']}
                fontWeight="500"
                bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                bgClip="text"
                paddingBottom="6px"
              >
                1. Use of Our Website, Products, and Services
              </Text>

              <Text fontSize="16px" fontWeight="500" color="var(--slate-400)">
                1.1. Eligibility
              </Text>
              <Text className="para-text">
                You must be at least 18 years old to use our website, products,
                and services. By using our services, you represent and warrant
                that you are of legal age.
              </Text>
              <Text fontSize="16px" fontWeight="500" color="var(--slate-400)">
                1.2. User Account
              </Text>
              <Text className="para-text">
                To access certain features of our products and services, you may
                be required to create an account. You are responsible for
                maintaining the confidentiality of your account credentials and
                for all activities that occur under your account.
              </Text>
              <Text fontSize="16px" fontWeight="500" color="var(--slate-400)">
                1.3. Acceptable Use
              </Text>
              <Text className="para-text">
                You agree to use our website, products, and services in
                compliance with all applicable laws and regulations. You may
                not:
              </Text>
              <Text className="para-text" paddingLeft="1rem">
                <ul>
                  <li>
                    Use our services for any unlawful or fraudulent purposes.
                  </li>
                  <li>Impersonate another person or entity.</li>
                  <li>
                    Attempt to gain unauthorized access to our systems or
                    networks.
                  </li>
                  <li>
                    Interfere with or disrupt the operation of our website or
                    services.
                  </li>
                  <li>
                    Engage in any activity that could harm our reputation or the
                    integrity of our services.
                  </li>
                </ul>
              </Text>
              <Text
                className="text-lg"
                lineHeight="1.5rem"
                // fontSize={['1.875rem', '2.25rem', '3rem']}
                fontWeight="500"
                bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                bgClip="text"
                paddingBottom="6px"
              >
                2. Intellectual Property
              </Text>
              <Text fontSize="16px" fontWeight="500" color="var(--slate-400)">
                2.1. Ownership
              </Text>
              <Text className="para-text">
                All content, materials, and intellectual property on our website
                and within our products and services, including but not limited
                to text, graphics, logos, trademarks, and software, are owned or
                licensed by us and are protected by copyright, trademark, and
                other intellectual property laws.
              </Text>
              <Text fontSize="16px" fontWeight="500" color="var(--slate-400)">
                2.2. Limited License
              </Text>
              <Text className="para-text">
                We grant you a limited, non-exclusive, non-transferable license
                to use our website, products, and services for personal and
                non-commercial purposes, subject to these Terms of Use.
              </Text>
              <Text
                className="text-lg"
                lineHeight="1.5rem"
                // fontSize={['1.875rem', '2.25rem', '3rem']}
                fontWeight="500"
                bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                bgClip="text"
                paddingBottom="6px"
              >
                3. Privacy
              </Text>
              <Text className="para-text">
                Your use of our website, products, and services is governed by
                our Privacy Policy, which outlines how we collect, use, and
                protect your personal information. By using our services, you
                consent to our data practices as described in the Privacy
                Policy.
              </Text>
              <Text
                className="text-lg"
                lineHeight="1.5rem"
                // fontSize={['1.875rem', '2.25rem', '3rem']}
                fontWeight="500"
                bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                bgClip="text"
                paddingBottom="6px"
              >
                4. Termination
              </Text>
              <Text className="para-text">
                We may, at our discretion, terminate or suspend your access to
                our website, products, or services at any time, with or without
                cause. Upon termination, your account and any related data may
                be deleted.
              </Text>
              <Text
                className="text-lg"
                lineHeight="1.5rem"
                // fontSize={['1.875rem', '2.25rem', '3rem']}
                fontWeight="500"
                bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                bgClip="text"
                paddingBottom="6px"
              >
                5. Disclaimer of Warranties
              </Text>
              <Text className="para-text">
                Our website, products, and services are provided "as is" and "as
                available," without any warranties or guarantees of any kind,
                whether express or implied. We do not warrant that our services
                will be error-free, uninterrupted, or secure.
              </Text>
              <Text
                className="text-lg"
                lineHeight="1.5rem"
                // fontSize={['1.875rem', '2.25rem', '3rem']}
                fontWeight="500"
                bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                bgClip="text"
                paddingBottom="6px"
              >
                6. Limitation of Liability
              </Text>
              <Text className="para-text">
                To the fullest extent permitted by law, we shall not be liable
                for any direct, indirect, incidental, special, consequential, or
                punitive damages arising out of or in connection with your use
                of our website, products, or services.
              </Text>
              <Text
                className="text-lg"
                lineHeight="1.5rem"
                // fontSize={['1.875rem', '2.25rem', '3rem']}
                fontWeight="500"
                bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                bgClip="text"
                paddingBottom="6px"
              >
                7. Changes to Terms of Use
              </Text>
              <Text className="para-text">
                We may update these Terms of Use from time to time. Any changes
                will be effective upon posting the revised Terms of Use on our
                website. Your continued use of our website, products, or
                services after such changes constitutes your acceptance of the
                updated Terms of Use.
              </Text>
              <Text
                className="text-lg"
                lineHeight="1.5rem"
                // fontSize={['1.875rem', '2.25rem', '3rem']}
                fontWeight="500"
                bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                bgClip="text"
                paddingBottom="6px"
              >
                8. Contact Us
              </Text>
              <Text className="para-text">
                If you have any questions or concerns about these Terms of Use,
                please contact us at info@zinkml.com.
              </Text>
              <Text className="para-text">
                Thank you for choosing ZinkML Technologies Private Limited
                Company. We look forward to serving you as you access our
                products and services.
              </Text>
            </Flex>
          </Grid>
        </Flex>
      </Flex>
      <CtaFooter1 />
      <FooterNew />
    </ChakraProvider>
  );
}

import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { Button } from '@chakra-ui/react';
// import { transform } from 'framer-motion';

export default function CtaButton(props) {
  return (
    <ChakraProvider>
      <Button
        as="a"
        target={'target' in props ? props.target : '_blank'}
        height="6"
        width={props.width}
        border="1px solid transparent"
        gap="6px"
        // padding="4px 11px"
        variant="outline"
        paddingX="3.5"
        paddingY="4"
        lineHeight="0.4em"
        fontSize=".75rem"
        borderRadius={props.borderRadius ? props.borderRadius : '6px'}
        // boxShadow="outline"
        href={props.link}
        bg="linear-gradient(#161628e0, #161628e0) padding-box,linear-gradient(to right, var(--orange-200), var(--sky-300)) border-box"
        color="var(--slate-200)"
        fontWeight="600"
        _hover={{
          bg: 'linear-gradient(#161628d0, #161628d0) padding-box,linear-gradient(to right, var(--orange-200), var(--sky-300)) border-box',
          transform: 'translatez(2px) scale(1.02)',
        }}
        _focus={{ boxShadow: 'none' }}
        _active={{ boxShadow: 'none' }}
        onClick={() => {
          if ('signup' in props) {
            if ('gtag_report_conversion' in window) {
              window.gtag_report_conversion(props.link);
            }
          }
        }}
      >
        <div>{props.icon}</div>
        {props.text}
      </Button>
    </ChakraProvider>
  );
}

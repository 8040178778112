import React from 'react';
import HomeHeaderNew from './HomeHeaderNew';
import HomeSection1 from './HomeSection1';
import FooterNew from './FooterNew';
import FeatureSliders from './HomeFeatureSliders';
import CtaFooter1 from './CtaFooter1';
import HomeUnique from './HomeUnique';
import HomeUSPNew from './HomeUSPNew';
import SecondaryNavComp from './SecondaryNavComp';
// import { SecondaryNavComps } from './NavComp';
import NavComp from './NavComp';
// import HomeDiagram from './HomeDiagram';
import BetaHeader from './BetaHeader';
import { useEffect } from 'react';
import HomeHeader from './HomeHeader';
import HomeImageSliders from './HomeImageSliders';
import HomeDiagram from './HomeDiagram';
import HomeProductNew from './HomeProductNew';
import { Helmet } from 'react-helmet';

export const homeNavItems = [
  ['Overview', 1, '/#homeTop', 'observed1'],
  ['Toolkit', 2, '/#ProductFlow', 'observed1'],
  ['USPs', 3, '/#USPs', 'observed1'],
  ['Features', 4, '/#ProductComponents', 'observed1'],
];
export const homeLeft = {
  text: '',
  link: '/',
};
export const productNavItems = [
  ['Overview', 1, '/Product#homeTop', 'observed2'],
  ['Connect', 2, '/Product#connect', 'observed2'],
  ['Clean', 3, '/Product#clean', 'observed2'],
  ['Transform', 4, '/Product#transform', 'observed2'],
  ['Toolkit', 5, '/#ProductFlow', 'observed2'],
  ['Features', 6, '/#ProductComponents', 'observed2'],
];
export const productLeft = {
  text: 'Product Components',
  link: '/Product',
};

export const aboutUsNavItems = [
  ['Mission', 1, '/about-us#mission', 'observed3'],
  ['Our Team', 2, '/about-us#team', 'observed3'],
  ['Careers', 3, '/about-us#careers', 'observed3'],
  ['Contact Us', 4, '/about-us#contactUs', 'observed3'],
];
export const aboutUsLeft = {
  text: '',
  link: '/',
};

export const SecondaryNavComps = {
  home: <SecondaryNavComp items={homeNavItems} left={homeLeft} />,
  product: <SecondaryNavComp items={productNavItems} left={productLeft} />,
  aboutUs: <SecondaryNavComp items={aboutUsNavItems} left={aboutUsLeft} />,
};
export function Home() {
  useEffect(() => {
    const intObserver1 = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        let index = entry.target.dataset['id'];
        if (
          document.querySelector(".observed1[data-id='" + index + "'") !== null
        ) {
          document
            .querySelector(".observed1[data-id='" + index + "'")
            .classList.toggle('hoverOnObserve', entry.isIntersecting);
        }
      });
    });
    const observers = document.querySelectorAll('.observer1');
    if (observers !== null) {
      observers.forEach(ele => {
        intObserver1.observe(ele);
      });
    }
  });

  return (
    <>
      <Helmet>
        <title>ZinkML: Zero-Code | End-to-End | Data Science Platform</title>
        <link rel="canonical" href="https://zinkml.com" />
        <meta
          name="description"
          content="Zero Code. End-to-End. Plug and Play Data Science Platform. ZinkML empowers you to build complex predictive data workflows and machine learning models without code into one one powerful platform. Perfected for experimentation."
        />
        <meta
          property="og:title"
          content="ZinkML: Zero-Code | End-to-End | Data Science Platform"
        />
        <meta
          name="og:description"
          content="Zero Code. End-to-End. Plug and Play Data Science Platform. ZinkML empowers you to build complex predictive data workflows and machine learning models without code into one one powerful platform. Perfected for experimentation."
        />
        <meta name="og:url" content="https://zinkml.com" />

        <meta
          property="twitter:title"
          content="ZinkML: Zero-Code | End-to-End | Data Science Platform"
        />
        <meta
          name="twitter:description"
          content="Zero Code. End-to-End. Plug and Play Data Science Platform. ZinkML empowers you to build complex predictive data workflows and machine learning models without code into one one powerful platform. Perfected for experimentation."
        />
        <meta name="twitter:url" content="https://zinkml.com" />
      </Helmet>
      <NavComp comp={SecondaryNavComps.home} />
      <HomeHeaderNew />
      <HomeSection1 />
      {/* <HomeHeader /> */}
      <HomeDiagram />
      <HomeUSPNew />
      <HomeProductNew />
      {/* <FeatureSliders /> */}
      {/* <HomeUnique /> */}
      <CtaFooter1 />
      <FooterNew />
    </>
  );
}

import { HamburgerIcon } from '@chakra-ui/icons';
import { Button, ChakraProvider, Flex, Text, Tooltip } from '@chakra-ui/react';
import React from 'react';
import CtaButton from './CtaButton';
import { ReactComponent as ZinkLogo } from './ZinkLogo.svg';
import Tippy, { tippy } from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/border.css';
// import ProductMenuItemsMobile from './ProductMenuItems';
// import WhyUsMenuItemsMobile from './WhyUsMenuItems';
import {
  ArrowUp,
  // Currency,
  Enterprise,
  // IntentRequestHeal,
  // LogicalPartition,
  User,
  Demo,
  PlayFilledAlt,
} from '@carbon/icons-react';

let ctaIcon = <PlayFilledAlt color="var(--orange-200)" size="16" />;
export default function MobileMenu() {
  document.addEventListener('click', e => {
    if (!e.target.closest('#details1')) {
      if (document.getElementById('details1') !== null) {
        document.getElementById('details1').removeAttribute('open');
      }
      // document.getElementById('details1').removeAttribute('open');
    }
    if (!e.target.closest('#details2')) {
      if (document.getElementById('details2') !== null) {
        document.getElementById('details2').removeAttribute('open');
      }
      // document.getElementById('details2').removeAttribute('open');
    }
  });
  function HamburgerOpen() {
    return (
      <Flex
        flexDirection="column"
        gap="3"
        alignItems="center"
        padding="10px"
        // width="200px"
      >
        {/* <details
          style={{ cursor: 'pointer' }}
          className="text-sm details1"
          id="details1"
        >
          <summary style={{ paddingLeft: '4px' }}>
            <Button
              as="div"
              className="text-sm"
              size="xs"
              variant="outline"
              colorScheme="blue"
              width="100px"
              // paddingLeft="2"
              justifyContent="flex-start"
              leftIcon={<LogicalPartition />}
            >
              Product
            </Button>
          </summary>
          <ProductMenuItemsMobile />
        </details>
        <details
          style={{ cursor: 'pointer' }}
          className="text-sm"
          id="details2"
        >
          <summary style={{ paddingLeft: '4px' }}>
            <Button
              as="div"
              className="text-sm"
              size="xs"
              variant="outline"
              colorScheme="blue"
              width="100px"
              justifyContent="flex-start"
              leftIcon={<IntentRequestHeal />}
            >
              Why Us
            </Button>
          </summary>
          <WhyUsMenuItemsMobile />
        </details>
        <Button
          as="a"
          href="/Pricing"
          className="text-sm"
          size="xs"
          variant="outline"
          colorScheme="blue"
          width="100px"
          marginLeft="25px"
          justifyContent="flex-start"
          leftIcon={<Currency />}
        >
          Pricing
        </Button> */}
        <Button
          as="a"
          href="/request-demo"
          className="text-sm"
          size="xs"
          variant="outline"
          color="var(--slate-200)"
          fontWeight="400"
          borderColor="var(--slate-700)"
          width="140px"
          marginLeft="5px"
          justifyContent="flex-start"
          height="30px"
          leftIcon={<Demo color="var(--pink-300)" />}
          _hover={{
            borderColor: 'var(--pink-300)',
            background: 'var(--slate-800)',
          }}
        >
          Book a Demo
        </Button>
        <Button
          as="a"
          target="_blank"
          href="https://app.zinkml.com/login"
          className="text-sm"
          size="xs"
          variant="outline"
          color="var(--slate-200)"
          fontWeight="400"
          borderColor="var(--slate-700)"
          width="140px"
          marginLeft="5px"
          justifyContent="flex-start"
          height="30px"
          leftIcon={<User color="var(--pink-300)" />}
          _hover={{
            borderColor: 'var(--pink-300)',
            background: 'var(--slate-800)',
          }}
        >
          Sign In
        </Button>
        <Button
          as="a"
          href="/about-us"
          className="text-sm"
          size="xs"
          variant="outline"
          color="var(--slate-200)"
          fontWeight="400"
          borderColor="var(--slate-700)"
          width="140px"
          marginLeft="5px"
          justifyContent="flex-start"
          height="30px"
          leftIcon={<Enterprise color="var(--pink-300)" />}
          _hover={{
            borderColor: 'var(--pink-300)',
            background: 'var(--slate-800)',
          }}
        >
          About Us
        </Button>
      </Flex>
    );
  }
  function HamburgerClose() {
    tippy.setDefaultProps({ maxWidth: '', padding: '' });
    return (
      <ChakraProvider>
        <Flex
          bg="gray.800"
          width="100vw"
          height="4rem"
          justifyContent="center"
          // paddingTop="1rem"
          // paddingBottom="1rem"
          position="fixed"
          top="0px"
          overflow="visible"
          zIndex="9997"
          borderBottom="2px solid var(--slate-800)"
          flexDir="column"
        >
          {/* <div
            className="header text-xs text-center"
            style={{
              display: 'inline',
              textAlign: 'center',
              width: '100%',
              height: 'max-content',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'absolute',
              top: '5px',
              left: '0',
              // padding: '0rem',
              zIndex: '9998',
              color: 'var(--sky-200)',
            }}
          >
            We would love to hear your &nbsp;
            <Link
              href="https://forms.gle/wdMjkTz63g7WA66BA"
              _hover={{ textDecor: 'underline' }}
              color="var(--lime-300)"
              target="_blank"
              fontStyle="italic"
              // display="contents"
            >
              feedback & queries.
            </Link>
          </div> */}
          <Flex
            justify="space-between"
            width="100%"
            // bg="black"
            // color="white"
            paddingX="2"
            gap="2"
            alignItems="center"
            className="text-xs"
          >
            <Flex as="a" alignItems="center" href="/">
              <ZinkLogo width="30px" height="30px" />
              <Text
                className="logoText"
                paddingLeft="2"
                fontWeight="bold"
                fontSize="22px"
              >
                ZinkML
              </Text>
            </Flex>
            {/* <Flex
              _hover={{ color: 'var(--purple-xx-300)' }}
              _focus={{ boxShadow: 'none' }}
              alignSelf="center"
            >
              <CtaButton
                text={'Demo'}
                icon={ctaIcon}
                link="https://app.zinkml.com/signup"
                width="60px"
              />
            </Flex> */}

            <Flex gap="10px">
              <Flex
                _hover={{ color: 'var(--purple-xx-300)' }}
                _focus={{ boxShadow: 'none' }}
                alignSelf="flex-end"
              >
                <CtaButton
                  text={'Try our App'}
                  icon={ctaIcon}
                  link="https://app.zinkml.com/signup?zm=1"
                  width="120px"
                  borderRadius="var(--round-full)"
                  target="_self"
                  signup="true"
                />
              </Flex>
              <Tippy
                content={HamburgerOpen()}
                trigger={'click'}
                interactive={true}
                interactiveBorder={0}
                theme={'tomato'}
                placement={'bottom-start'}
                maxWidth={'10rem'}
              >
                <button>
                  <HamburgerIcon
                    boxSize={6}
                    color="var(--pink-300)"
                    id="singleElement"
                    onClick={e => e.target.classList.toggle('clicked')}
                  />
                </button>
              </Tippy>
            </Flex>
          </Flex>
        </Flex>
        <Tooltip label="Back to top" hasArrow>
          <Button
            as="a"
            size="xs"
            position="fixed"
            bottom="10px"
            right="10px"
            href="#homeTop"
            bg="gray.900"
            color="gray.300"
            _hover={{ color: 'blue.400' }}
            _focus={{ boxShadow: 'none' }}
            _active={{ boxShadow: 'none' }}
          >
            <ArrowUp />
          </Button>
        </Tooltip>
        <Tooltip label="Book a Demo" hasArrow>
          <Button
            as="a"
            size="xs"
            position="fixed"
            bottom="10px"
            right="45px"
            href="/request-demo"
            bg="gray.900"
            color="gray.300"
            _hover={{ color: 'blue.400' }}
            _focus={{ boxShadow: 'none' }}
            _active={{ boxShadow: 'none' }}
          >
            <Demo />
          </Button>
        </Tooltip>
      </ChakraProvider>
    );
  }
  return <HamburgerClose />;
}

import React from 'react';
import NavComp from './NavComp';
import CtaFooter1 from './CtaFooter1';
import FooterNew from './FooterNew';
import HubSpotFeedbackQueryForm from './HubspotFeedbackQueryForm';
import { Helmet } from 'react-helmet';

export function FeedbackQueryForm() {
  return (
    <div>
      <Helmet>
        <title>ZinkML: Queries</title>
        <link rel="canonical" href="https://zinkml.com/feedback-query" />
        <meta
          name="description"
          content="Share your thoughts and queries about our Data Platform"
        />
        <meta property="og:title" content="ZinkML: Queries" />
        <meta
          name="og:description"
          content="Share your thoughts and queries about our Data Platform"
        />
        <meta name="og:url" content="https://zinkml.com/feedback-query" />

        <meta property="twitter:title" content="ZinkML: Queries" />
        <meta
          name="twitter:description"
          content="Share your thoughts and queries about our Data Platform"
        />
        <meta name="twitter:url" content="https://zinkml.com/feedback-query" />
      </Helmet>
      <NavComp />
      <HubSpotFeedbackQueryForm
        portalId="46788316"
        formId="34c29bed-0547-4651-b139-f12029709998"
      />
      <CtaFooter1 />
      <FooterNew />
    </div>
  );
}

import {
  ChakraProvider,
  Text,
  Box,
  Flex,
  Image,
  CircularProgress,
  IconButton,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import ZinkMlConnect from './ZinkMlConnect.png';
import ZinkMlConnect2 from './ZinkMlConnect2.png';
import ZinkMlClean from './ZinkMlClean.png';
import ViewTable from './ViewTable.png';
import ViewCharts from './ViewCharts.png';
// import './Styles.css';
import { NextFilled, PreviousFilled } from '@carbon/icons-react';
import gsap from 'gsap';
import { TextPlugin } from 'gsap/TextPlugin';

gsap.registerPlugin(TextPlugin);

export const imgInfo = [
  {
    name: ZinkMlConnect,
    dataId: '0',
    heading: 'Connect',
    link: '/',
  },
  {
    name: ZinkMlClean,
    dataId: '1',
    heading: 'Clean',
    link: '/',
  },
  {
    name: ZinkMlConnect2,
    dataId: '2',
    heading: 'ExpPredict',
    link: '/',
  },
  {
    name: ZinkMlConnect2,
    dataId: '3',
    heading: 'ExpDeploy',
    link: '/',
  },
  {
    name: ViewTable,
    dataId: '4',
    heading: 'Visualize1',
    link: '/',
  },
  {
    name: ViewCharts,
    dataId: '5',
    heading: 'Visualize2',
    link: '/',
  },
];

export default function TestPage() {
  const d = useRef();
  const [progress, setProgress] = useState(0);
  const [timer, setTimer] = useState(0);
  // const progressId = useRef();
  useEffect(() => {
    createTimer();
    return () => {
      clearInterval(d.current);
    };
  });
  const createTimer = () => {
    d.current = setInterval(() => handler(false), 500);
  };

  function handler(clear = false, dir = -1) {
    setTimer((timer + 1) % 11);
    setProgress((progress + 10) % 110);
    if (clear || timer === 10) {
      setTimer(0);
      setProgress(0);

      document.querySelectorAll('.imageSlide').forEach(
        e => (e.dataset.id = (parseInt(e.dataset.id) + (dir === 1 ? 1 : 5)) % 6) // 1 : (n-1))% n  where n equals length of imgInfo
      );
      let newText = document
        .querySelector(".imageSlide[data-id='0']")
        .getAttribute('data-text');
      gsap.fromTo(
        '#headingText',
        {
          duration: 0.2,
          text: {
            value: '',
            // delimiter: '',
          },
        },
        {
          duration: 1,
          text: {
            value: newText,
            // delimiter: '',
          },
          ease: 'power4.inOut',
        }
      );
    }
  }

  return (
    <ChakraProvider>
      <Flex
        width="100%"
        height="100%"
        justifyContent="center"
        flexDirection="column"
        paddingTop="12"
      >
        <Flex width="100%" style={{ aspectRatio: '2 / 1' }}>
          <Flex
            direction="column"
            zIndex="100"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress
              value={progress}
              size="25px"
              position="absolute"
              thickness="8px"
            ></CircularProgress>
            <IconButton
              isRound="true"
              size="20px"
              bg="transparent"
              _hover={{
                bgGradient: 'linear(to-l, #FF0080, #7928CA)',
                brightness: '150%',
                transform: 'translatez(2px)',
                // opacity: '1.5',
              }}
              _focus={{ boxShadow: 'none' }}
              _active={{ boxShadow: 'none' }}
              icon={
                <PreviousFilled
                  onClick={() => handler(true, 1)}
                  size="20px"
                  fill="gray"
                />
              }
            />
          </Flex>
          <Box
            position="relative"
            width="100%"
            padding="5px"
            overflow="visible"
            onMouseEnter={() => {
              if (d.current) clearInterval(d.current);
            }}
            onMouseLeave={() => {
              createTimer();
            }}
          >
            {imgInfo.map(el => {
              return (
                <ChakraProvider>
                  <Image
                    width="100%"
                    className="imageSlide"
                    data-id={el.dataId}
                    position="absolute"
                    src={el.name}
                    data-text={el.heading}
                    // objectFit="cover"
                    visibility="hidden"
                    transition="all 1s ease-in"
                  ></Image>
                </ChakraProvider>
              );
            })}
          </Box>
          <Flex
            direction="column"
            zIndex="100"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress
              value={progress}
              size="25px"
              position="absolute"
              thickness="8px"
            ></CircularProgress>
            <IconButton
              isRound="true"
              size="20px"
              bg="transparent"
              _hover={{
                bgGradient: 'linear(to-l, #FF0080, #7928CA)',
                brightness: '150%',
                transform: 'translatez(2px)',
                opacity: '1.5',
              }}
              _focus={{ boxShadow: 'none' }}
              _active={{ boxShadow: 'none' }}
              icon={
                <NextFilled
                  onClick={() => handler(true, -1)}
                  size="20px"
                  fill="gray"
                />
              }
            />
          </Flex>
        </Flex>
        <Flex
          justifyContent="center"
          position="relative"
          height="24"
          alignItems="center"
        >
          <ChakraProvider>
            <Text
              id="headingText"
              display="flex"
              position="absolute"
              justifyContent="center"
              alignItems="center"
              className="text-5xl"
              color="var(--sky-400)"
              // fontSize="56px"
              fontWeight="bold"
              // className="imageSlide"
              // data-id={el.dataId}
            >
              {/* {el.heading} */}
              Connect
            </Text>
          </ChakraProvider>
        </Flex>
      </Flex>
    </ChakraProvider>
  );
}

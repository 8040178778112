import React from 'react';
import { Flex, Text, Link } from '@chakra-ui/react';
import CtaButton from './CtaButton';
import { Idea, PlayFilledAlt } from '@carbon/icons-react';

let ctaIcon = <PlayFilledAlt color="var(--orange-200)" size="16" />;
let mailIcon = <Idea color="var(--orange-200)" size="16" />;
export function CtaFooter1() {
  return (
    <Flex
      width="100vw"
      justify="center"
      // bg="#f5f5f7"
      // className="bgTop"
      padding="25px"
      paddingBottom="0"
      flexDir="column"
      alignItems="center"
      // borderBottom="1px solid var(--slate-800)"
      // borderRadius={['10px', '10px', '20px', '20px', '20px']}
    >
      <Flex
        maxWidth="400px"
        width="98vw"
        height="128px"
        // bg="var(--chart-bg1)"
        // bg="blackAlpha.900"
        borderRadius={['10px', '10px', '20px', '16px', '16px']}
        border="1px solid var(--slate-800)"
        // bg="#fff"
        direction="column"
        // paddingBottom="0"
        alignItems="center"
        justifyContent="center"
      >
        <Text
          className="text-base"
          fontWeight="500"
          letterSpacing=".007em"
          color="var(--sky-300)"
        >
          Ready to get started
        </Text>
        <Flex direction={['column', 'row']} padding="0px" gap="10px">
          <Link
            // color="#d0d0d0"
            _hover={{ color: '#ffffff' }}
            // href="https://app.zinkml.com/signup"
            fontSize="12px"
            // lineHeight="1.7"
            paddingTop="15px"
            // width="164px"
          >
            <CtaButton
              text="Try our App"
              link="https://app.zinkml.com/signup?zm=1"
              width={['148px', '164px', '164px', '164px', '164px']}
              icon={ctaIcon}
              target="_self"
              signup="true"
              borderRadius="var(--round-full)"
            />
          </Link>
          <Link
            color="#d0d0d0"
            _hover={{ color: '#ffffff' }}
            href="/feedback-query"
            fontSize="12px"
            // lineHeight="3.7"
            paddingTop="15px"
          >
            <CtaButton
              text="Feedback & Queries"
              width="164px"
              icon={mailIcon}
              borderRadius="var(--round-full)"
            />
          </Link>
        </Flex>
      </Flex>
      {/* <FooterNew /> */}
    </Flex>
  );
}
export default CtaFooter1;

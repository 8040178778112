import { Flex, Text, ChakraProvider, Box } from '@chakra-ui/react';
import React from 'react';
import CtaButton from './CtaButton';
import BetaHeader from './BetaHeader';
import { Application, ArrowRight } from '@carbon/icons-react';
// import TestPage from './HomeHeaderSlider';
// import HomeHeaderBackground from './HomeHeaderBackground.svg';
let ctaIcon = <ArrowRight color="var(--orange-200)" size="16" />;
function AboutUsMission() {
  return (
    <ChakraProvider>
      <Flex
        id="homeTop"
        className="observer3"
        data-id="1"
        width="100vw"
        paddingTop={['7rem', '7rem', '6rem', '6rem']}
        justifyContent="center"
      >
        <Flex
          width="100%"
          maxWidth="1280px"
          paddingY={['4', '12', '16', '16']}
          justifyContent="center"
          grow="1"
          paddingX={['2', '2', '5']}
        >
          <Flex
            boxShadow="0 0 0 1px #00000020, 0 9px 9px #0000000f, 0 7px 7px #0000000d, 0 5px 4px #0000000a, 0 3px 3px #00000008, 0 2px 2px #00000005"
            position="relative"
            // bg="var(--chart-bg1)"
            bg="linear-gradient(145deg, rgb(34, 32, 38), rgb(22, 28, 41))"
            borderRadius="20px"
            // borderTopRadius={['0', '10px', '20px', '30px', '30px']}
            borderTop="1px solid var(--slate-800)"
            // width={['100%', '95%', '95%', '95%', '95%']}
            width="100%"
            overflow="hidden"
            flexDir={{
              base: 'column',
              md: 'row',
              lg: 'row',
              xl: 'row',
              '2xl': 'row',
            }}
            padding={{
              base: '10px',
              md: '15px',
              lg: '72px 16px 72px',
              xl: '72px 16px 72px',
              '2xl': '72px 16px 72px',
            }}
            justifyContent="flex-start"
            gap={{
              base: '40px',
              md: '20px',
              lg: '20px',
              xl: '20px',
              '2xl': '20px',
            }}
          >
            {/* <BetaHeader /> */}
            <Flex
              direction="column"
              grow="1"
              justify="flex-start"
              padding="46px 0 20px"
              maxWidth="96%"
              margin="0 auto"
            >
              <Box paddingBottom="10px">
                <Text
                  fontSize={['30px', '40px', '50px', '50px', '50px']}
                  lineHeight="1.4"
                  fontWeight="600"
                  letterSpacing="-.015em"
                  bgGradient="linear-gradient(to bottom, var(--orange-200), var(--sky-400))"
                  bgClip="text"
                  transition="all ease-in-out 300ms"
                  textAlign="center"
                  _hover={{
                    bgGradient:
                      'linear-gradient(to bottom, var(--orange-200), var(--sky-400))',
                    brightness: '150%',
                    transform: 'translatez(10px) scale(1.02)',
                    opacity: '1',
                  }}
                >
                  Our Mission: <br />
                  Transforming Data Science,
                  <br /> Simplifying Innovation
                </Text>
              </Box>
              {/* <Box
                className="smallDivider"
                width="25px"
                color="myTertiary.500"
              ></Box> */}
            </Flex>
            {/* <Flex
              width="100%"
              maxWidth="325px"
              grow={[1, 1, 0, 0, 0]}
              // bg="#f63e54"
              height="500px"
              // borderRadius="30px"
              // padding={['10px', '15px', '20px', '20px', '20px']}
              justify="center"
              alignItems="center"
              alignSelf="center"
              alignContent="center"
            > */}
            {/* <Text fontSize="56px" fontWeight="600">
                Connect
              </Text> */}
            {/* <TestPage /> */}
            {/* </Flex> */}
          </Flex>
        </Flex>
      </Flex>
    </ChakraProvider>
  );
}

export default AboutUsMission;

/* ********************ColorStyles************************** 
Dark blue: #02042a ;background color
Lighter Dark Blue: 
White: #FFFFFF ; Text color
Light Blue: #2b84ea ;hover color
*/
// import { theme } from "@chakra-ui/react"

// theme.js

let colorScheme = { //blackAlpha
    primaryColor: {
        900: '#020303',
        800: '#050609',
        700: '#090C10',
        600: '#0E1116',
        500: '#11141B',
        400: '#151820',
        300: '#171B24',
        200: '#191D28',
        100: '#191E28',
        50: '#1A1F29',
    },
    secondaryColor: { //whiteAlpha
        900: '#EDEEEE',
        800: '#D1D2D5',
        700: '#ACAFB3',
        600: '#888B90',
        500: '#6D7077',
        400: '#51555D',
        300: '#40444D',
        200: '#2D313C',
        100: '#282D37',
        50: '#242933',
    },
    tertiaryColor: { //purple
        900: '#521B41',
        800: '#702459',
        700: '#97266D',
        600: '#B83280',
        500: '#D53F8C',
        400: '#ED64A6',
        300: '#F687B3',
        200: '#FBB6CE',
        100: '#FED7E2',
        50: '#FFF5F7',
    },
    additionalColors: {
        macBackgroundLightGray: '#2A2632',
        macBackgrounddarkGray: '#211D29',
        macBackgroundPurple: '#30234E',
        macTextColor: '#DDDCDE',
        macIconStrokeColor: '#6B6773',
        macButtonColor: '#8C368B',
        macLinkColor: '#5A9AF8',
        macBorderDarkColor: '#000000',
        macBorderLightColor: '#433F4B',

    }
}
export const ColorSystem = {
    normal: {
        mainBackgroundColor: colorScheme.additionalColors.macBackgroundLightGray,
        sectionBackgroundColor: colorScheme.additionalColors.macBackgroundDarkGray,
        sectionBorderColor: colorScheme.additionalColors.macBorderDarkColor,
        boxBackgroundColor: colorScheme.additionalColors.macBackgroundDarkGray,
        HeadingBackgroundColor: colorScheme.additionalColors.macBackgroundDarkGray,
        subHeadingBackgroundColor: colorScheme.additionalColors.macBackgroundDarkGray,
        textBackgroundColor: colorScheme.additionalColors.macBackgroundDarkGray,
        boxBordercolor: colorScheme.additionalColors.macBorderDarkColor,
        pageHeadingColor: colorScheme.additionalColors.macTextColor,
        headingColor: colorScheme.additionalColors.macTextColor,
        subheadingColor: colorScheme.additionalColors.macTextColor,
        paraTextColor: colorScheme.additionalColors.macTextColor,
        darkTextColor: colorScheme.additionalColors.macTextColor,
        lightTextColor: 'white',
        buttonColor: colorScheme.additionalColors.macButtonColor,
        iconBackgroundColor: colorScheme.additionalColors.macBackgroundLightGray,
        iconStrokeColor: colorScheme.macIconStrokeColor,
        iconBorderColor: colorScheme.macBorderLightColor,
        linkTextColor: colorScheme.macLinkColor,
    },
    hover: {
        mainBackgroundColor: 'color1',
        sectionBackgroundColor: 'color1',
        sectionBorderColor: 'color1',
        boxBackgroundColor: 'color1',
        HeadingBackgroundColor: 'color1',
        subHeadingBackgroundColor: 'color1',
        textBackgroundColor: 'color1',
        boxBordercolor: 'color1',
        pageHeadingColor: 'color1',
        headingColor: 'color1',
        subheadingColor: 'color1',
        paraTextColor: 'color1',
        darkTextColor: 'color1',
        lightTextColor: 'color1',
        buttonColor: 'color1',
        iconBackgroundColor: 'color1',
        iconStrokeColor: 'color1',
        iconBorderColor: 'color1',
        linkTextColor: 'color1'
    },
    action: {
        mainBackgroundColor: 'color1',
        sectionBackgroundColor: 'color1',
        sectionBorderColor: 'color1',
        boxBackgroundColor: 'color1',
        HeadingBackgroundColor: 'color1',
        subHeadingBackgroundColor: 'color1',
        textBackgroundColor: 'color1',
        boxBordercolor: 'color1',
        pageHeadingColor: 'color1',
        headingColor: 'color1',
        subheadingColor: 'color1',
        paraTextColor: 'color1',
        darkTextColor: 'color1',
        lightTextColor: 'color1',
        buttonColor: 'color1',
        iconBackgroundColor: 'color1',
        iconStrokeColor: 'color1',
        iconBorderColor: 'color1',
        linkTextColor: 'color1'
    },

}
export const Spacings = {
    sectionPaddingTop: '28px',
    sectionPaddingBottom: '28px',
    sectionPaddingRight: '10px',
    sectionPaddingLeft: '10px',
    navPaddingLeft: '20px',
    navPaddingRight: '20px',
    borderWidth: '1px',
    textSpacingLeft: '5px',
    textSpacingTop: '2px',
    textSpacingRight: '5px',
    textSpacingBottom: '2px',
    textpadding: '5px'
}
export const TextStyles = {
    pageHeading: {
        size: '2.5rem',
        weight: '900',
        lineHeight: '2.5rem',
        letterSpacing: '0.025rem'
    },
    heading1: {
        size: '2rem',
        weight: '700',
        lineHeight: '1.5rem',
        letterSpacing: '0rem'
    },
    heading2: {
        size: '1.5rem',
        weight: '500',
        lineHeight: '1.5rem',
        letterSpacing: '0rem'
    },
    heading3: {
        size: '1.25rem',
        weight: '400',
        lineHeight: '1.5rem',
        letterSpacing: '0rem'
    },
    para: {
        size: '1rem',
        weight: '400',
        lineHeight: '1.5rem',
        letterSpacing: '.1rem'
    },
    link: {
        size: '1rem',
        weight: '400',
        lineHeight: '1.5rem',
        letterSpacing: '0rem'
    },
    caption: {
        size: '0.75rem',
        weight: '400',
        lineHeight: '1.5rem',
        letterSpacing: '0rem'
    }
}

export const Theme = {
    colors: {
        darkBg: '#02042a',
        lightDarkBg: '#02042b',
        lightBg: '#EDFDFD',
        lighterBg: '#FFFFFF',
        lightTextColor: '#FFFFFF',
        hoverColor: '#2b84ea',
        medTextColor: '#718096',
        darkTextColor: '#171923',
        lightBorderColor: '#718096',
        pinkGradientColor: '#7928CA',
        purpleGradientColor: '#FF0080'
    },
    fonts: {
        body: "system-ui, sans-serif",
        heading: "Georgia, serif",
        mono: "Menlo, monospace",
    },
    fontSizes: {
        xs: "0.75rem",
        sm: "0.875rem",
        md: "1rem",
        lg: "1.125rem",
        xl: "1.25rem",
        "2xl": "1.5rem",
        "3xl": "1.875rem",
        "4xl": "2.25rem",
        "5xl": "3rem",
        "6xl": "3.75rem",
        "7xl": "4.5rem",
        "8xl": "6rem",
        "9xl": "8rem",
    },
    fontWeights: {
        hairline: 100,
        thin: 200,
        light: 300,
        normal: 400,
        medium: 500,
        semibold: 600,
        bold: 700,
        extrabold: 800,
        black: 900,
    },
    lineHeights: {
        normal: "normal",
        none: 1,
        shorter: 1.25,
        short: 1.375,
        base: 1.5,
        tall: 1.625,
        taller: "2",
        "3": ".75rem",
        "4": "1rem",
        "5": "1.25rem",
        "6": "1.5rem",
        "7": "1.75rem",
        "8": "2rem",
        "9": "2.25rem",
        "10": "2.5rem",
    },
    letterSpacings: {
        tighter: "-0.05em",
        tight: "-0.025em",
        normal: "0",
        wide: "0.025em",
        wider: "0.05em",
        widest: "0.1em",
    }
}

// import { extendTheme } from '@chakra-ui/react'

// const breakpoints = {
//   sm: '30em',
//   md: '48em',
//   lg: '62em',
//   xl: '80em',
//   '2xl': '96em',
// }

// const theme = extendTheme({ breakpoints, ... })
import React from 'react';
import {
  ChakraProvider,
  Flex,
  Text,
  Box,
  Grid,
  Center,
} from '@chakra-ui/react';
import epiWebImagesConnect from './epiWebImagesConnect.png';
import epiWebImagesClean from './epiWebImagesClean.png';
import { Link } from 'react-router-dom';
import CtaButton from './CtaButton';

export const features = [
  {
    id: 'connectH',
    heading: 'Connect Data',
    subHeading: 'Multiple Data Sources, One Location',
    color: 'var(--sky-300)',
    paraText:
      'A One Stop solution to fetch data from different sources including Amazon S3, SQL Servers, Databases etc. and merge them onto a single platform. Accepts all popular file types. Easy to access and configure all data sources with just a few clicks.',
    imageLink: epiWebImagesConnect,
    exploreLink: '/',
  },
  {
    id: 'cleanH',
    heading: 'Process Data',
    subHeading: 'Clean, Transform, Automate',
    color: 'var(--emerald-300)',
    paraText:
      'Lots of tools to get data in a proper format. Powerful data manipulation tools - slice, filter, conditional edit, add, transform, group. Detects and corrects data quality. See the data as it changes - just as in Excel. Create data pipelines: use, reuse and automate data operations.',
    imageLink: epiWebImagesClean,
    exploreLink: '/',
  },
  {
    id: 'visualizeH',
    heading: 'Visualize',
    subHeading: 'Use Charts as analytics tools',
    color: 'var(--purple-xx-300)',
    paraText:
      'See the data as you process it - just as in excel. Automated graphs capture basic analytics at each step. Simple to create and customize graphs with zoom, pan, select and compare. Drill down functionality to interact with charts. Works as a great ETL tool with wide range of plotting capabilities including visual output of Decision Tree Models.',
    imageLink: epiWebImagesClean,
    exploreLink: '/',
  },
  {
    id: 'predictH',
    heading: 'Predictive Analytics',
    subHeading: 'From Basic to advanced models',
    color: 'var(--orange-300)',
    paraText:
      'Rich set of models including Neural Networks, Tree Models, Probabilistic Models. Complete flexibility - customize every part of each model. Recommends the next best operation at each step. Store and deploy models at any time. Complete tranparency, better explainability - Get the ins and outs of all models. Monitor model performance on all metrics.',
    imageLink: epiWebImagesClean,
    exploreLink: '/',
  },
  {
    id: 'deployH',
    heading: 'Hassle-free Deployment',
    subHeading: 'Deploy models with a few clicks',
    color: 'var(--blue-300)',
    paraText:
      'Deploy saved models with just a few clicks. Customize and autoscale your deployment as and when you want. Provides API endpoint - Enables easy integration with existing tools. Excellent tracing and testing tools to monitor and correct deployment errors.Advanced logging and visualization ensure you have complete control over all your deployments at every time.',
    imageLink: epiWebImagesClean,
    exploreLink: '/',
  },

  {
    heading: 'Real time Collaboration',
    subHeading: 'Multiple users, Many Projects, One Platform',
    color: 'var(--pink-300)',
    paraText:
      'Simple, intuitive project sharing between users. Multiple teams can view and edit the workflow at the same time - enabling different users to work on different parts of the same data science project. Easily create, share and download projects. Brings users from technical and business teams to the same place enabling true co-development of solutions.',
    imageLink: epiWebImagesClean,
    exploreLink: '/',
  },
];

function FeatureSliders() {
  return (
    <Flex
      width="100vw"
      className="observer1 bgTop"
      data-id="6"
      id="ProductComponents"
      // bg="#f5f5f7"
      paddingY="12"
      paddingX="5"
      direction="column"
      alignItems="center"
      // color="#1d1d1f"
    >
      {features.map(feature => (
        <Flex
          key={feature.id}
          id={feature.id}
          maxWidth="1080px"
          boxShadow="xl"
          // bg="var(--chart-bg1)"
          bg="blackAlpha.700"
          borderRadius="20px"
          border="1px solid var(--slate-800)"
          // paddingTop={['20px', '40px', '40px']}
          // paddingBottom={['20px', '20px', '20px']}
          marginY="12"
          paddingTop="12"
          paddingBottom="7"
          direction="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Text
            fontSize={['32px', '40px', '48px']}
            // lineHeight={['1.07', '1.1', '1.125']}
            fontWeight="500"
            // letterSpacing={['.004em', '0em', '-.005em']}
            color={feature.color}
            // paddingLeft={['25px', '55px']}
            paddingLeft="5"
            alignSelf="flex-start"
            // paddingBottom={['10px', '15px', '25px']}
          >
            {feature.heading}
          </Text>
          <Grid
            templateColumns="repeat(12,minmax(0,1fr))"
            gap="10"
            // padding={['0 15px', '0 25px']}
            paddingX="5"
            width="100%"
          >
            {/* Grid Item Full width Tile */}
            <Flex
              gridColumn="span 12"
              // bg="#fff"
              // borderRadius={['30px', '10px', '20px', '30px', '30px']}
              borderRadius="10px"
              direction="column"
              paddingTop="8"
            >
              <Text
                fontSize={['21px', '21px', '21px']}
                // lineHeight={['1.07', '1.1', '1.125']}
                fontWeight="500"
                // letterSpacing={['0.011em', '.012em']}
                color={feature.color}
                // paddingLeft={['10px', '30px']}
                paddingLeft="5"
                alignSelf="flex-start"
              >
                {feature.subHeading}
              </Text>
              <Text
                fontSize={['17px', '17px', '17px']}
                // lineHeight={['1.07', '1.1', '1.125']}
                fontWeight="400"
                // letterSpacing={['0.011em', '.012em']}
                // paddingLeft={['10px', '30px']}
                // paddingRight={['15px', '10px']}
                paddingX="5"
                alignSelf="flex-start"
                // paddingBottom="25px"
                // paddingTop="4"
                paddingY="4"
                maxWidth={['100%', '80%', '80%', '60%', '60%']}
              >
                {feature.paraText}
              </Text>
              <Flex
                alignSelf="center"
                paddingTop="8"
                paddingBottom="5"
                paddingX="5"
                // flexDirection="column"
                // paddingTop={['40px', '50px', '60px']}
                // paddingLeft={['20px', '20px', '40px']}
                // paddingRight={['20px', '20px', '40px']}
                // paddingBottom={['30px', '30px', 0]}
                width={['100%', '90%', '80%', '80%']}
              >
                <Box
                  // src={feature.imageLink}
                  bg="var(--neutral-900)"
                  boxSize={['200px', '300px', '400px', '400px']}
                  flexGrow="1"
                  // borderTopRadius={['30px', '30px']}
                  borderTopRadius="20px"
                ></Box>
              </Flex>
            </Flex>
          </Grid>

          <Link to={feature.exploreLink} as="button">
            <CtaButton text="ExploreMore" />
            {/* <Text>Explore More</Text> */}
          </Link>
        </Flex>
      ))}
      <Center
        maxWidth="1080px"
        className="smallDivider"
        width="calc(100% - 40px)"
        color="myTertiary.500"
        alignSelf="center"
        marginY="10"
      ></Center>
    </Flex>
  );
}
// Colors Set
// --violet: #8876fe;
// --magenta: #d71bce;
// --plum: #8d1287;
// --indigo: #622aff;
// --grape: #6529f8;
// --azure: #027fff;
// --tomato: #f63e54;
// --orange: #e66702;
// --green: #0c9d46;
// --forest: #0c8346;
// --sea-green: #099b9d;
// --teal: #049cb7;
// --aqua: #039e8f;
// --moonstone: #2294b1;
// --midnight: #1b23b3;
// --sapphire: #3d44c3;
export default FeatureSliders;

import React from 'react';
import { Route, Routes } from 'react-router-dom';
// import { Product } from './Product';
import { Home } from './Home';
// import WhyUs from './WhyUs';
// import Pricing from './Pricing';
import { AboutUs } from './AboutUs';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfUse from './TermsOfUse';
import CookiePolicy from './CookiePolicy';
import { RequestDemoForm } from './RequestDemoForm';
import { FeedbackQueryForm } from './FeedbackQueryForm';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
      {/* <Route path="/Product" element={<Product />}></Route>
      <Route path="/WhyUs" element={<WhyUs />}></Route>
      <Route path="/Pricing" element={<Pricing />}></Route> */}
      <Route path="/about-us" element={<AboutUs />}></Route>
      <Route path="/request-demo" element={<RequestDemoForm />}></Route>
      <Route path="/feedback-query" element={<FeedbackQueryForm />}></Route>
      <Route path="/terms-of-use" element={<TermsOfUse />}></Route>
      <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
      <Route path="/cookie-policy" element={<CookiePolicy />}></Route>
    </Routes>
  );
}
export default App;

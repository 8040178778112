import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { Button } from '@chakra-ui/react';
// import { transform } from 'framer-motion';

export default function InPageButton(props) {
  return (
    <ChakraProvider>
      <Button
        as="a"
        // target="_blank"
        height="6"
        width="164px"
        border="1px solid transparent"
        gap="6px"
        // padding="4px 11px"
        variant="outline"
        paddingX="3.5"
        paddingY="4"
        lineHeight="0.4em"
        fontSize=".75rem"
        borderRadius="6px"
        // boxShadow="outline"
        href={props.link}
        bg="linear-gradient(#161628e0, #161628e0) padding-box,linear-gradient(to right, var(--sky-700), var(--teal-700)) border-box"
        color="var(--slate-200)"
        fontWeight="normal"
        _hover={{
          bg: 'linear-gradient(#161628c0, #161628c0) padding-box,linear-gradient(to right, var(--sky-700), var(--teal-700)) border-box',
          transform: 'translatez(2px) scale(1.02)',
        }}
        _focus={{ boxShadow: 'none' }}
        _active={{ boxShadow: 'none' }}
      >
        {props.icon}
        {props.text}
      </Button>
    </ChakraProvider>
  );
}

import {
  ChakraProvider,
  Show,
  Flex,
  Text,
  Box,
  Grid,
  Link,
  Image,
} from '@chakra-ui/react';
import aboutUsRR from './AboutUsRR.jpeg';
import aboutUsSumit from './AboutUsSumit.jpeg';
import aboutUsRishav from './AboutUsRishav.jpeg';
import aboutUsPraween from './AboutUsPraween.jpeg';
import aboutUsShiv from './AboutUsShiv.jpeg';
import aboutUsSushant from './AboutUsSushant.jpeg';
import { LogoLinkedin } from '@carbon/icons-react';

export function AboutUsHalfGrid() {
  return (
    <ChakraProvider>
      <Flex
        id="team"
        className="observer3"
        data-id="2"
        width="100vw"
        justify="center"
        paddingY="4rem"
        // bg="#f5f5f7"
        // color="#1d1d1f"
        flexDir="column"
        gap="2rem"
      >
        <Flex
          flexDir="column"
          lineHeight="1.35"
          fontSize={['30px', '40px', '50px', '50px', '50px']}
          fontWeight="600"
          letterSpacing="-.015em"
          bgGradient="linear-gradient(0deg, var(--slate-500), var(--slate-300))"
          bgClip="text"
          transition="all ease-in-out 300ms"
          textAlign="center"
          _hover={{
            bgGradient:
              'linear-gradient(0deg, var(--slate-500), var(--slate-300))',
            transform: 'translatez(10px) scale(1.02)',
            opacity: '1',
          }}
        >
          Our Team
          <Box
            className="smallDivider"
            width="calc(min(500px, 90%))"
            margin="24px auto"
            bg="linear-gradient(45deg, var(--slate-900),var(--slate-500), var(--slate-900))"
          ></Box>
        </Flex>

        <Flex
          width="calc(min(700px, 96%))"
          direction="column"
          margin="0 auto"
          gap="2rem"
        >
          <Grid
            templateColumns="repeat(12,minmax(0,1fr))"
            gap="0"
            // paddingY="2rem"
            // paddingX="5"
            padding="0"
            width="100%"
            boxShadow="0 0 0 1px #00000020, 0 9px 9px #0000000f, 0 7px 7px #0000000d, 0 5px 4px #0000000a, 0 3px 3px #00000008, 0 2px 2px #00000005"
            borderTop="1px solid var(--slate-800)"
            borderRadius="var(--round-xxl)"
            overflow="hidden"
          >
            <Flex
              gridColumn={['span 12', 'span 9', 'span 9']}
              direction="column"
              justify="center"
              // paddingX="5"
              // paddingY="10"
              padding="1rem 1rem 1rem 2rem"
              gap="5px"
              // maxWidth="450px"
              bg="linear-gradient(145deg, rgb(16, 32, 36), rgb(26, 26, 26) 100%)"
            >
              <Flex gap="10px">
                <Text
                  className="text-2xl"
                  lineHeight="1.5rem"
                  // fontSize={['1.875rem', '2.25rem', '3rem']}
                  fontWeight="500"
                  bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                  bgClip="text"
                  paddingBottom="6px"
                >
                  Rajeev Ranjan
                </Text>
                <Link href="https://www.linkedin.com/in/rajeev-ranjan-cfa/">
                  <LogoLinkedin size="24px" fill="var(--sky-500)" />
                </Link>
              </Flex>

              <Text
                fontSize="16px"
                fontWeight="500"
                // color={props.item.color}
                color="var(--slate-400)"
                // bgGradient="linear-gradient(0deg, var(--emerald-300), var(--sky-300))"
                // bgClip="text"
                // paddingLeft="10px"
              >
                Cofounder & CEO
              </Text>
              <Text
                fontSize="14px"
                fontWeight="500"
                paddingBottom="12px"
                color="var(--slate-400)"

                // paddingLeft="10px"
              >
                <ul style={{ paddingLeft: '20px', lineHeight: 1.6 }}>
                  <li>
                    8+ years of experience in Investment Banking and Treasury
                  </li>
                  <li>
                    UBS Investment Bank, ING Bank, and Kotak Mahindra Bank
                  </li>
                  <li>B. Tech from IIT Kharagpur</li>
                  <li>MBA from VGSOM, IIT Kharagpur</li>
                  <li>CFA® charterholder, CFA Institute</li>
                </ul>
              </Text>
              {/* <LogoLinkedin size="24px" fill="var(--sky-500)" /> */}
            </Flex>
            <Flex
              gridColumn={['span 12', 'span 3', 'span 3']}
              // bg="var(--neutral-800)"
              borderRadius="0"
              minH={['none', '280px', '280px', '280px', '280px']}
              padding="10px"
              overflow="hidden"
              bg="linear-gradient(-145deg, rgb(16, 32, 36), rgb(26, 26, 26) 100%)"
              justifyContent="center"
              alignItems="center"
            >
              <Image
                borderRadius="full"
                boxSize="150px"
                src={aboutUsRR}
                alt="Rajeev Ranjan"
                style={{ objectFit: 'cover' }}
              />
            </Flex>
          </Grid>
          <Grid
            templateColumns="repeat(12,minmax(0,1fr))"
            gap="0"
            // paddingY="2rem"
            // paddingX="5"
            padding="0"
            width="100%"
            boxShadow="0 0 0 1px #00000020, 0 9px 9px #0000000f, 0 7px 7px #0000000d, 0 5px 4px #0000000a, 0 3px 3px #00000008, 0 2px 2px #00000005"
            borderTop="1px solid var(--slate-800)"
            borderRadius="var(--round-xxl)"
            bg="linear-gradient(145deg, #24281e 30%, #1d2224 100%)"
            overflow="hidden"
          >
            <Flex
              gridColumn={['span 12', 'span 3', 'span 3']}
              // bg="var(--neutral-800)"
              borderRadius="0"
              minH={['none', '280px', '280px', '280px', '280px']}
              padding="10px"
              overflow="hidden"
              bg="linear-gradient(45deg, rgb(16, 32, 36), rgb(26, 26, 26) 100%)"
              justifyContent="center"
              alignItems="center"
            >
              <Image
                borderRadius="full"
                boxSize="150px"
                src={aboutUsSushant}
                alt="Sushant Kumar"
                style={{ objectFit: 'cover' }}
              />
            </Flex>
            <Flex
              gridColumn={['span 12', 'span 9', 'span 9']}
              direction="column"
              justify="center"
              // paddingX="5"
              // paddingY="10"
              // padding="0"
              padding="1rem 1rem 1rem 2rem"
              gap="5px"
              // maxWidth="450px"
              bg="linear-gradient(145deg, rgb(26, 26, 26), rgb(16, 32, 36) 100%)"
            >
              <Flex gap="10px">
                <Text
                  className="text-2xl"
                  lineHeight="1.5rem"
                  // fontSize={['1.875rem', '2.25rem', '3rem']}
                  fontWeight="500"
                  bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                  bgClip="text"
                  paddingBottom="6px"
                >
                  Sushant Kumar
                </Text>
                <Link href="https://www.linkedin.com/in/sushantmithila/">
                  <LogoLinkedin size="24px" fill="var(--sky-500)" />
                </Link>
              </Flex>

              <Text
                fontSize="16px"
                fontWeight="500"
                // color="transparent"
                color="var(--slate-400)"
                // bgGradient="linear-gradient(0deg, var(--emerald-300), var(--sky-300))"
                // bgClip="text"
                // paddingLeft="10px"
              >
                Cofounder & CTO
              </Text>
              <Text
                fontSize="14px"
                fontWeight="500"
                paddingBottom="12px"
                color="var(--slate-400)"

                // paddingLeft="10px"
              >
                <ul style={{ paddingLeft: '20px', lineHeight: 1.6 }}>
                  <li>
                    Software Engineer and Researcher with 15+ years of
                    experience in backend and frontend application development
                  </li>
                  <li>Cofounder at Oranggy and Emlii Media</li>
                  <li>B. Tech from IIT Kharagpur</li>
                  <li>M. Tech from IIT Kharagpur</li>
                </ul>
              </Text>
            </Flex>
          </Grid>
          <Grid
            templateColumns="repeat(12,minmax(0,1fr))"
            gap="0"
            // paddingY="2rem"
            // paddingX="5"
            padding="0"
            width="100%"
            boxShadow="0 0 0 1px #00000020, 0 9px 9px #0000000f, 0 7px 7px #0000000d, 0 5px 4px #0000000a, 0 3px 3px #00000008, 0 2px 2px #00000005"
            borderTop="1px solid var(--slate-800)"
            borderRadius="var(--round-xxl)"
            bg="linear-gradient(145deg, #24281e 30%, #1d2224 100%)"
            overflow="hidden"
          >
            <Flex
              gridColumn={['span 12', 'span 9', 'span 9']}
              direction="column"
              justify="center"
              // paddingX="5"
              // paddingY="10"
              // padding="0"
              padding="1rem 1rem 1rem 2rem"
              gap="5px"
              // maxWidth="450px"
              bg="linear-gradient(145deg, rgb(16, 32, 36), rgb(26, 26, 26) 100%)"
            >
              <Flex gap="10px">
                <Text
                  className="text-2xl"
                  lineHeight="1.5rem"
                  // fontSize={['1.875rem', '2.25rem', '3rem']}
                  fontWeight="500"
                  bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                  bgClip="text"
                  paddingBottom="6px"
                >
                  Praween Kumar
                </Text>
                <Link href="https://www.linkedin.com/in/praween-kumar/">
                  <LogoLinkedin size="24px" fill="var(--sky-500)" />
                </Link>
              </Flex>

              <Text
                fontSize="16px"
                fontWeight="500"
                // color="transparent"
                color="var(--slate-400)"
                // bgGradient="linear-gradient(0deg, var(--emerald-300), var(--sky-300))"
                // bgClip="text"
                // paddingLeft="10px"
              >
                Head of Product (Founding member)
              </Text>
              <Text
                fontSize="14px"
                fontWeight="500"
                paddingBottom="12px"
                color="var(--slate-400)"

                // paddingLeft="10px"
              >
                <ul style={{ paddingLeft: '20px', lineHeight: 1.6 }}>
                  <li>
                    10+ years of experience in Product Management and Data
                    Innovation
                  </li>
                  <li>Digite, YantraLive, SilverMine, DataWeave.</li>
                  <li> B.E. and MBA (IIM-K)</li>
                </ul>
              </Text>
            </Flex>
            <Flex
              gridColumn={['span 12', 'span 3', 'span 3']}
              // bg="var(--neutral-800)"
              borderRadius="0"
              minH={['none', '280px', '280px', '280px', '280px']}
              padding="10px"
              overflow="hidden"
              bg="linear-gradient(-145deg, rgb(16, 32, 36), rgb(26, 26, 26) 100%)"
              justifyContent="center"
              alignItems="center"
            >
              <Image
                borderRadius="full"
                boxSize="150px"
                src={aboutUsPraween}
                alt="Praween Kumar"
                style={{ objectFit: 'cover' }}
              />
            </Flex>
          </Grid>
          <Grid
            templateColumns="repeat(12,minmax(0,1fr))"
            gap="0"
            // paddingY="2rem"
            // paddingX="5"
            padding="0"
            width="100%"
            boxShadow="0 0 0 1px #00000020, 0 9px 9px #0000000f, 0 7px 7px #0000000d, 0 5px 4px #0000000a, 0 3px 3px #00000008, 0 2px 2px #00000005"
            borderTop="1px solid var(--slate-800)"
            borderRadius="var(--round-xxl)"
            overflow="hidden"
          >
            <Flex
              gridColumn={['span 12', 'span 3', 'span 3']}
              // bg="var(--neutral-800)"
              borderRadius="0"
              minH={['none', '280px', '280px', '280px', '280px']}
              padding="10px"
              overflow="hidden"
              bg="linear-gradient(45deg, rgb(16, 32, 36), rgb(26, 26, 26) 100%)"
              justifyContent="center"
              alignItems="center"
            >
              <Image
                borderRadius="full"
                boxSize="150px"
                src={aboutUsSumit}
                alt="Sumit Bhosale"
                style={{ objectFit: 'cover' }}
              />
            </Flex>
            <Flex
              gridColumn={['span 12', 'span 9', 'span 9']}
              direction="column"
              justify="center"
              // paddingX="5"
              // paddingY="10"
              padding="1rem 1rem 1rem 2rem"
              gap="5px"
              // maxWidth="450px"
              bg="linear-gradient(145deg, rgb(26, 26, 26), rgb(16, 32, 36) 100%)"
            >
              <Flex gap="10px">
                <Text
                  className="text-2xl"
                  lineHeight="1.5rem"
                  // fontSize={['1.875rem', '2.25rem', '3rem']}
                  fontWeight="500"
                  bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                  bgClip="text"
                  paddingBottom="6px"
                >
                  Sumit Bhosale
                </Text>
                <Link href="https://www.linkedin.com/in/sumit-bhosale-yantralive/">
                  <LogoLinkedin size="24px" fill="var(--sky-500)" />
                </Link>
              </Flex>

              <Text
                fontSize="16px"
                fontWeight="500"
                // color={props.item.color}
                color="var(--slate-400)"
                // bgGradient="linear-gradient(0deg, var(--emerald-300), var(--sky-300))"
                // bgClip="text"
                // paddingLeft="10px"
              >
                Strategy Advisor
              </Text>
              <Text
                fontSize="14px"
                fontWeight="500"
                paddingBottom="12px"
                color="var(--slate-400)"

                // paddingLeft="10px"
              >
                <ul style={{ paddingLeft: '20px', lineHeight: 1.6 }}>
                  <li>Cofounder at yantralive.com</li>
                  <li>
                    8+ years of experience in the Real Estate and Home Interior
                    Business
                  </li>
                  <li>Founding team member of Mygubbi and Lynx</li>
                  <li>B.E and MBA (IIM-K)</li>
                </ul>
              </Text>
              {/* <LogoLinkedin size="24px" fill="var(--sky-500)" /> */}
            </Flex>
          </Grid>

          <Grid
            templateColumns="repeat(12,minmax(0,1fr))"
            gap="0"
            // paddingY="2rem"
            // paddingX="5"
            padding="0"
            width="100%"
            boxShadow="0 0 0 1px #00000020, 0 9px 9px #0000000f, 0 7px 7px #0000000d, 0 5px 4px #0000000a, 0 3px 3px #00000008, 0 2px 2px #00000005"
            borderTop="1px solid var(--slate-800)"
            borderRadius="var(--round-xxl)"
            overflow="hidden"
          >
            <Flex
              gridColumn={['span 12', 'span 9', 'span 9']}
              direction="column"
              justify="center"
              // paddingX="5"
              // paddingY="10"
              padding="1rem 1rem 1rem 2rem"
              gap="5px"
              // maxWidth="450px"
              bg="linear-gradient(145deg, rgb(16, 32, 36), rgb(26, 26, 26) 100%)"
            >
              <Flex gap="10px">
                <Text
                  className="text-2xl"
                  lineHeight="1.5rem"
                  // fontSize={['1.875rem', '2.25rem', '3rem']}
                  fontWeight="500"
                  bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                  bgClip="text"
                  paddingBottom="6px"
                >
                  Shivaprasad Chennoju
                </Text>
                <Link href="https://www.linkedin.com/in/shivaprasad-chennoju-ab701251/">
                  <LogoLinkedin size="24px" fill="var(--sky-500)" />
                </Link>
              </Flex>

              <Text
                fontSize="16px"
                fontWeight="500"
                // color={props.item.color}
                color="var(--slate-400)"
                // bgGradient="linear-gradient(0deg, var(--emerald-300), var(--sky-300))"
                // bgClip="text"
                // paddingLeft="10px"
              >
                Product Advisor
              </Text>
              <Text
                fontSize="14px"
                fontWeight="500"
                paddingBottom="12px"
                color="var(--slate-400)"

                // paddingLeft="10px"
              >
                <ul style={{ paddingLeft: '20px', lineHeight: 1.6 }}>
                  <li>
                    10+ years of experience in Product and Marketing Strategy.
                  </li>
                  <li>Dell, Oracle, MindTree, Infosys</li>
                  <li> B.E and MBA (IIM-K)</li>
                </ul>
              </Text>
              {/* <LogoLinkedin size="24px" fill="var(--sky-500)" /> */}
            </Flex>
            <Flex
              gridColumn={['span 12', 'span 3', 'span 3']}
              // bg="var(--neutral-800)"
              borderRadius="0"
              minH={['none', '280px', '280px', '280px', '280px']}
              padding="10px"
              overflow="hidden"
              bg="linear-gradient(-145deg, rgb(16, 32, 36), rgb(26, 26, 26) 100%)"
              justifyContent="center"
              alignItems="center"
            >
              <Image
                borderRadius="full"
                boxSize="150px"
                src={aboutUsShiv}
                alt="Shivaprasad Chennoju"
                style={{ objectFit: 'cover' }}
              />
            </Flex>
          </Grid>
        </Flex>
      </Flex>
    </ChakraProvider>
  );
}

export function AboutUsHalfGridMobile() {
  return (
    <ChakraProvider>
      <Flex
        id="team"
        className="observer3"
        data-id="2"
        width="100vw"
        justify="center"
        paddingY="4rem"
        // bg="#f5f5f7"
        // color="#1d1d1f"
        flexDir="column"
        gap="2rem"
      >
        <Flex
          flexDir="column"
          lineHeight="1.35"
          fontSize={['30px', '40px', '50px', '50px', '50px']}
          fontWeight="600"
          letterSpacing="-.015em"
          bgGradient="linear-gradient(0deg, var(--slate-500), var(--slate-300))"
          bgClip="text"
          transition="all ease-in-out 300ms"
          textAlign="center"
          _hover={{
            bgGradient:
              'linear-gradient(0deg, var(--slate-500), var(--slate-300))',
            transform: 'translatez(10px) scale(1.02)',
            opacity: '1',
          }}
        >
          Our Team
          <Box
            className="smallDivider"
            width="calc(min(500px, 90%))"
            margin="24px auto"
            bg="linear-gradient(45deg, var(--slate-900),var(--slate-500), var(--slate-900))"
          ></Box>
        </Flex>

        <Flex
          width="calc(min(700px, 96%))"
          direction="column"
          margin="0 auto"
          gap="2rem"
        >
          <Grid
            templateColumns="repeat(12,minmax(0,1fr))"
            gap="0"
            // paddingY="2rem"
            // paddingX="5"
            padding="0"
            width="100%"
            boxShadow="0 0 0 1px #00000020, 0 9px 9px #0000000f, 0 7px 7px #0000000d, 0 5px 4px #0000000a, 0 3px 3px #00000008, 0 2px 2px #00000005"
            borderTop="1px solid var(--slate-800)"
            borderRadius="var(--round-xxl)"
            overflow="hidden"
          >
            <Flex
              gridColumn={['span 12', 'span 3', 'span 3']}
              // bg="var(--neutral-800)"
              borderRadius="0"
              minH={['none', '280px', '280px', '280px', '280px']}
              padding="10px"
              overflow="hidden"
              bg="linear-gradient(180deg, rgb(16, 32, 36), rgb(26, 26, 26) 100%)"
              justifyContent="center"
              alignItems="center"
            >
              <Image
                borderRadius="full"
                boxSize="150px"
                src={aboutUsRR}
                alt="Rajeev Ranjan"
                style={{ objectFit: 'cover' }}
              />
            </Flex>
            <Flex
              gridColumn={['span 12', 'span 9', 'span 9']}
              direction="column"
              justify="center"
              // paddingX="5"
              // paddingY="10"
              padding="1rem 1rem 1rem 2rem"
              gap="5px"
              // maxWidth="450px"
              bg="linear-gradient(0deg, rgb(16, 32, 36), rgb(26, 26, 26) 100%)"
            >
              <Flex gap="10px">
                <Text
                  className="text-2xl"
                  lineHeight="1.5rem"
                  // fontSize={['1.875rem', '2.25rem', '3rem']}
                  fontWeight="500"
                  bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                  bgClip="text"
                  paddingBottom="6px"
                >
                  Rajeev Ranjan
                </Text>
                <Link href="https://www.linkedin.com/in/rajeev-ranjan-cfa/">
                  <LogoLinkedin size="24px" fill="var(--sky-500)" />
                </Link>
              </Flex>

              <Text
                fontSize="16px"
                fontWeight="500"
                // color={props.item.color}
                color="var(--slate-400)"
                // bgGradient="linear-gradient(0deg, var(--emerald-300), var(--sky-300))"
                // bgClip="text"
                // paddingLeft="10px"
              >
                Cofounder & CEO
              </Text>
              <Text
                fontSize="14px"
                fontWeight="500"
                paddingBottom="12px"
                color="var(--slate-400)"

                // paddingLeft="10px"
              >
                <ul style={{ paddingLeft: '20px', lineHeight: 1.6 }}>
                  <li>
                    8+ years of experience in Investment Banking and Treasury
                  </li>
                  <li>
                    UBS Investment Bank, ING Bank, and Kotak Mahindra Bank
                  </li>
                  <li>B. Tech from IIT Kharagpur</li>
                  <li>MBA from VGSOM, IIT Kharagpur</li>
                  <li>CFA® charterholder, CFA Institute</li>
                </ul>
              </Text>
              {/* <LogoLinkedin size="24px" fill="var(--sky-500)" /> */}
            </Flex>
          </Grid>
          <Grid
            templateColumns="repeat(12,minmax(0,1fr))"
            gap="0"
            // paddingY="2rem"
            // paddingX="5"
            padding="0"
            width="100%"
            boxShadow="0 0 0 1px #00000020, 0 9px 9px #0000000f, 0 7px 7px #0000000d, 0 5px 4px #0000000a, 0 3px 3px #00000008, 0 2px 2px #00000005"
            borderTop="1px solid var(--slate-800)"
            borderRadius="var(--round-xxl)"
            bg="linear-gradient(145deg, #24281e 30%, #1d2224 100%)"
            overflow="hidden"
          >
            <Flex
              gridColumn={['span 12', 'span 3', 'span 3']}
              // bg="var(--neutral-800)"
              borderRadius="0"
              minH={['none', '280px', '280px', '280px', '280px']}
              padding="10px"
              overflow="hidden"
              bg="linear-gradient(180deg, rgb(16, 32, 36), rgb(26, 26, 26) 100%)"
              justifyContent="center"
              alignItems="center"
            >
              <Image
                borderRadius="full"
                boxSize="150px"
                src={aboutUsSushant}
                alt="Sushant Kumar"
                style={{ objectFit: 'cover' }}
              />
            </Flex>
            <Flex
              gridColumn={['span 12', 'span 9', 'span 9']}
              direction="column"
              justify="center"
              // paddingX="5"
              // paddingY="10"
              // padding="0"
              padding="1rem 1rem 1rem 2rem"
              gap="5px"
              // maxWidth="450px"
              bg="linear-gradient(0deg, rgb(16, 32, 36), rgb(26, 26, 26) 100%)"
            >
              <Flex gap="10px">
                <Text
                  className="text-2xl"
                  lineHeight="1.5rem"
                  // fontSize={['1.875rem', '2.25rem', '3rem']}
                  fontWeight="500"
                  bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                  bgClip="text"
                  paddingBottom="6px"
                >
                  Sushant Kumar
                </Text>
                <Link href="https://www.linkedin.com/in/sushantmithila/">
                  <LogoLinkedin size="24px" fill="var(--sky-500)" />
                </Link>
              </Flex>

              <Text
                fontSize="16px"
                fontWeight="500"
                // color="transparent"
                color="var(--slate-400)"
                // bgGradient="linear-gradient(0deg, var(--emerald-300), var(--sky-300))"
                // bgClip="text"
                // paddingLeft="10px"
              >
                Cofounder & CTO
              </Text>
              <Text
                fontSize="14px"
                fontWeight="500"
                paddingBottom="12px"
                color="var(--slate-400)"

                // paddingLeft="10px"
              >
                <ul style={{ paddingLeft: '20px', lineHeight: 1.6 }}>
                  <li>
                    Software Engineer and Researcher with 15+ years of
                    experience in backend and frontend application development
                  </li>
                  <li>Cofounder at Oranggy and Emlii Media</li>
                  <li>B. Tech from IIT Kharagpur</li>
                  <li>M. Tech from IIT Kharagpur</li>
                </ul>
              </Text>
            </Flex>
          </Grid>
          <Grid
            templateColumns="repeat(12,minmax(0,1fr))"
            gap="0"
            // paddingY="2rem"
            // paddingX="5"
            padding="0"
            width="100%"
            boxShadow="0 0 0 1px #00000020, 0 9px 9px #0000000f, 0 7px 7px #0000000d, 0 5px 4px #0000000a, 0 3px 3px #00000008, 0 2px 2px #00000005"
            borderTop="1px solid var(--slate-800)"
            borderRadius="var(--round-xxl)"
            bg="linear-gradient(145deg, #24281e 30%, #1d2224 100%)"
            overflow="hidden"
          >
            <Flex
              gridColumn={['span 12', 'span 3', 'span 3']}
              // bg="var(--neutral-800)"
              borderRadius="0"
              minH={['none', '280px', '280px', '280px', '280px']}
              padding="10px"
              overflow="hidden"
              bg="linear-gradient(180deg, rgb(16, 32, 36), rgb(26, 26, 26) 100%)"
              justifyContent="center"
              alignItems="center"
            >
              <Image
                borderRadius="full"
                boxSize="150px"
                src={aboutUsPraween}
                alt="Praween Kumar"
                style={{ objectFit: 'cover' }}
              />
            </Flex>
            <Flex
              gridColumn={['span 12', 'span 9', 'span 9']}
              direction="column"
              justify="center"
              // paddingX="5"
              // paddingY="10"
              // padding="0"
              padding="1rem 1rem 1rem 2rem"
              gap="5px"
              // maxWidth="450px"
              bg="linear-gradient(0deg, rgb(16, 32, 36), rgb(26, 26, 26) 100%)"
            >
              <Flex gap="10px">
                <Text
                  className="text-2xl"
                  lineHeight="1.5rem"
                  // fontSize={['1.875rem', '2.25rem', '3rem']}
                  fontWeight="500"
                  bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                  bgClip="text"
                  paddingBottom="6px"
                >
                  Praween Kumar
                </Text>
                <Link href="https://www.linkedin.com/in/praween-kumar/">
                  <LogoLinkedin size="24px" fill="var(--sky-500)" />
                </Link>
              </Flex>

              <Text
                fontSize="16px"
                fontWeight="500"
                // color="transparent"
                color="var(--slate-400)"
                // bgGradient="linear-gradient(0deg, var(--emerald-300), var(--sky-300))"
                // bgClip="text"
                // paddingLeft="10px"
              >
                Head of Product (Founding member)
              </Text>
              <Text
                fontSize="14px"
                fontWeight="500"
                paddingBottom="12px"
                color="var(--slate-400)"

                // paddingLeft="10px"
              >
                <ul style={{ paddingLeft: '20px', lineHeight: 1.6 }}>
                  <li>
                    10+ years of experience in Product Management and Data
                    Innovation
                  </li>
                  <li>Digite, YantraLive, SilverMine, DataWeave.</li>
                  <li> B.E. and MBA (IIM-K)</li>
                </ul>
              </Text>
            </Flex>
          </Grid>
          <Grid
            templateColumns="repeat(12,minmax(0,1fr))"
            gap="0"
            // paddingY="2rem"
            // paddingX="5"
            padding="0"
            width="100%"
            boxShadow="0 0 0 1px #00000020, 0 9px 9px #0000000f, 0 7px 7px #0000000d, 0 5px 4px #0000000a, 0 3px 3px #00000008, 0 2px 2px #00000005"
            borderTop="1px solid var(--slate-800)"
            borderRadius="var(--round-xxl)"
            overflow="hidden"
          >
            <Flex
              gridColumn={['span 12', 'span 3', 'span 3']}
              // bg="var(--neutral-800)"
              borderRadius="0"
              minH={['none', '280px', '280px', '280px', '280px']}
              padding="10px"
              overflow="hidden"
              bg="linear-gradient(180deg, rgb(16, 32, 36), rgb(26, 26, 26) 100%)"
              justifyContent="center"
              alignItems="center"
            >
              <Image
                borderRadius="full"
                boxSize="150px"
                src={aboutUsSumit}
                alt="Sumit Bhosale"
                style={{ objectFit: 'cover' }}
              />
            </Flex>
            <Flex
              gridColumn={['span 12', 'span 9', 'span 9']}
              direction="column"
              justify="center"
              // paddingX="5"
              // paddingY="10"
              padding="1rem 1rem 1rem 2rem"
              gap="5px"
              // maxWidth="450px"
              bg="linear-gradient(0deg, rgb(16, 32, 36), rgb(26, 26, 26) 100%)"
            >
              <Flex gap="10px">
                <Text
                  className="text-2xl"
                  lineHeight="1.5rem"
                  // fontSize={['1.875rem', '2.25rem', '3rem']}
                  fontWeight="500"
                  bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                  bgClip="text"
                  paddingBottom="6px"
                >
                  Sumit Bhosale
                </Text>

                <Link href="https://www.linkedin.com/in/sumit-bhosale-yantralive/">
                  <LogoLinkedin size="24px" fill="var(--sky-500)" />
                </Link>
              </Flex>
              <Text
                fontSize="16px"
                fontWeight="500"
                // color={props.item.color}
                color="var(--slate-400)"
                // bgGradient="linear-gradient(0deg, var(--emerald-300), var(--sky-300))"
                // bgClip="text"
                // paddingLeft="10px"
              >
                Strategy Advisor
              </Text>
              <Text
                fontSize="14px"
                fontWeight="500"
                paddingBottom="12px"
                color="var(--slate-400)"

                // paddingLeft="10px"
              >
                <ul style={{ paddingLeft: '20px', lineHeight: 1.6 }}>
                  <li>Cofounder at yantralive.com</li>
                  <li>
                    8+ years of experience in the Real Estate and Home Interior
                    Business
                  </li>
                  <li>Founding team member of Mygubbi and Lynx</li>
                  <li>B.E and MBA (IIM-K)</li>
                </ul>
              </Text>
              {/* <LogoLinkedin size="24px" fill="var(--sky-500)" /> */}
            </Flex>
          </Grid>

          <Grid
            templateColumns="repeat(12,minmax(0,1fr))"
            gap="0"
            // paddingY="2rem"
            // paddingX="5"
            padding="0"
            width="100%"
            boxShadow="0 0 0 1px #00000020, 0 9px 9px #0000000f, 0 7px 7px #0000000d, 0 5px 4px #0000000a, 0 3px 3px #00000008, 0 2px 2px #00000005"
            borderTop="1px solid var(--slate-800)"
            borderRadius="var(--round-xxl)"
            overflow="hidden"
          >
            <Flex
              gridColumn={['span 12', 'span 3', 'span 3']}
              // bg="var(--neutral-800)"
              borderRadius="0"
              minH={['none', '280px', '280px', '280px', '280px']}
              padding="10px"
              overflow="hidden"
              bg="linear-gradient(180deg, rgb(16, 32, 36), rgb(26, 26, 26) 100%)"
              justifyContent="center"
              alignItems="center"
            >
              <Image
                borderRadius="full"
                boxSize="150px"
                src={aboutUsShiv}
                alt="Shivaprasad Chennoju"
                style={{ objectFit: 'cover' }}
              />
            </Flex>
            <Flex
              gridColumn={['span 12', 'span 9', 'span 9']}
              direction="column"
              justify="center"
              // paddingX="5"
              // paddingY="10"
              padding="1rem 1rem 1rem 2rem"
              gap="5px"
              // maxWidth="450px"
              bg="linear-gradient(0deg, rgb(16, 32, 36), rgb(26, 26, 26) 100%)"
            >
              <Flex gap="10px">
                <Text
                  className="text-2xl"
                  lineHeight="1.5rem"
                  // fontSize={['1.875rem', '2.25rem', '3rem']}
                  fontWeight="500"
                  bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                  bgClip="text"
                  paddingBottom="6px"
                >
                  Shivaprasad Chennoju
                </Text>
                <Link href="https://www.linkedin.com/in/shivaprasad-chennoju-ab701251/">
                  <LogoLinkedin size="24px" fill="var(--sky-500)" />
                </Link>
              </Flex>

              <Text
                fontSize="16px"
                fontWeight="500"
                // color={props.item.color}
                color="var(--slate-400)"
                // bgGradient="linear-gradient(0deg, var(--emerald-300), var(--sky-300))"
                // bgClip="text"
                // paddingLeft="10px"
              >
                Product Advisor
              </Text>
              <Text
                fontSize="14px"
                fontWeight="500"
                paddingBottom="12px"
                color="var(--slate-400)"

                // paddingLeft="10px"
              >
                <ul style={{ paddingLeft: '20px', lineHeight: 1.6 }}>
                  <li>
                    10+ years of experience in Product and Marketing Strategy.
                  </li>
                  <li>Dell, Oracle, MindTree, Infosys</li>
                  <li> B.E and MBA (IIM-K)</li>
                </ul>
              </Text>
              {/* <LogoLinkedin size="24px" fill="var(--sky-500)" /> */}
            </Flex>
          </Grid>
        </Flex>
      </Flex>
    </ChakraProvider>
  );
}

export default function AboutUsTeam() {
  return (
    <ChakraProvider>
      <Show above="md">
        <AboutUsHalfGrid />
      </Show>
      <Show below="md">
        <AboutUsHalfGridMobile />
      </Show>
    </ChakraProvider>
  );
}

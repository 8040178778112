import { extendTheme } from "@chakra-ui/react"

const theme = extendTheme({
    colors: {
        myDark: {
            50: '#f5f0f9',
            100: '#d9d4de',
            200: '#bfb7c5',
            300: '#a59bae',
            400: '#8a7f97',
            500: '#71657d',
            600: '#574f62',
            700: '#3e3846',
            800: '#25212b',
            900: '#0e0a12',
        },
        myLight: {
            50: '#f2f2f8',
            100: '#d9d8da',
            200: '#bebdbf',
            300: '#a5a4a5',
            400: '#8b8a8c',
            500: '#727072',
            600: '#595859',
            700: '#403e40',
            800: '#272527',
            900: '#100a0d',
        },
        myTertiary: {
            50: '#fbebfd',
            100: '#e7c9eb',
            200: '#d4a6d9',
            300: '#c283c9',
            400: '#af60b8',
            500: '#96479f',
            600: '#75367c',
            700: '#54265a',
            800: '#331637',
            900: '#150517',
        },
        myLink: '#5A9AF8',
    },
})

export default theme
import { ChakraProvider, Flex, Text, Box, Link, Grid } from '@chakra-ui/react';
import react from 'react';
import NavComp from './NavComp';
import FooterNew from './FooterNew';
import CtaFooter1 from './CtaFooter1';
import { Helmet } from 'react-helmet';
// import './Styles.css';

export default function PrivacyPolicy() {
  return (
    <ChakraProvider>
      <Helmet>
        <title>ZinkML: Privacy Policy</title>
        <link rel="canonical" href="https://zinkml.com/privacy-policy" />
        <meta
          name="description"
          content="Please see ZinkML's Privacy Policy here. If you have any questions regarding this information, please feel free to contact us at any time."
        />
        <meta property="og:title" content="ZinkML: Privacy Policy" />
        <meta
          name="og:description"
          content="Please see ZinkML's Privacy Policy here. If you have any questions regarding this information, please feel free to contact us at any time."
        />
        <meta name="og:url" content="https://zinkml.com/privacy-policy" />

        <meta property="twitter:title" content="ZinkML: Privacy Policy" />
        <meta
          name="twitter:description"
          content="Please see ZinkML's Privacy Policy here. If you have any questions regarding this information, please feel free to contact us at any time."
        />
        <meta name="twitter:url" content="https://zinkml.com/privacy-policy" />
      </Helmet>
      <NavComp />
      <Flex
        id="homeTop"
        width="100vw"
        justify="center"
        paddingY="7rem"
        // bg="#f5f5f7"
        // color="#1d1d1f"
        flexDir="column"
        gap="2rem"
      >
        <Text
          // fontSize={['40px', '40px', '50px', '60px', '60px']}
          lineHeight="1.35"
          className="text-5xl"
          fontWeight="600"
          letterSpacing="-.015em"
          bgGradient="linear-gradient(145deg, var(--amber-300), var(--purple-xx-300))"
          bgClip="text"
          transition="all ease-in-out 300ms"
          textAlign="center"
          _hover={{
            bgGradient:
              'linear-gradient(145deg, var(--amber-400), var(--purple-xx-400))',
            transform: 'translatez(10px) scale(1.02)',
            opacity: '1',
          }}
        >
          Privacy Policy
        </Text>

        <Flex
          width="calc(min(1080px, 96%))"
          direction="column"
          margin="0 auto"
          gap="4rem"
        >
          <Grid
            templateColumns="repeat(1,minmax(0,1fr))"
            gap="0"
            // paddingY="2rem"
            // paddingX="5"
            padding="0"
            width="100%"
            boxShadow="0 0 0 1px #00000020, 0 9px 9px #0000000f, 0 7px 7px #0000000d, 0 5px 4px #0000000a, 0 3px 3px #00000008, 0 2px 2px #00000005"
            borderTop="1px solid var(--slate-800)"
            borderRadius="var(--round-xxl)"
            overflow="hidden"
          >
            <Flex
              gridColumn={['span 12', 'span 12', 'span 9']}
              direction="column"
              justify="center"
              // paddingX="5"
              // paddingY="10"
              padding="3rem 1rem 3rem 2rem"
              gap="5px"
              // maxWidth="450px"
              bg="#1a1e1f"
            >
              <Text className="para-text">
                Last Updated: 18th September, 2024
              </Text>
              <Text className="para-text">
                Welcome to ZinkML Technologies Private Limited Company, ("we,"
                "our," or "us"). We are committed to protecting your privacy and
                ensuring the security of your personal information. This Privacy
                Policy explains how we collect, use, disclose, and protect your
                information when you use our website and services.
              </Text>
              <Text className="para-text">
                Please take a moment to read this Privacy Policy carefully. By
                accessing or using our website or services, you acknowledge that
                you have read, understood, and agree to be bound by this Privacy
                Policy.
              </Text>
              <Text
                className="text-lg"
                lineHeight="1.5rem"
                // fontSize={['1.875rem', '2.25rem', '3rem']}
                fontWeight="500"
                bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                bgClip="text"
                paddingBottom="6px"
              >
                1. Information We Collect
              </Text>

              <Text fontSize="16px" fontWeight="500" color="var(--slate-400)">
                1.1. Information You Provide
              </Text>
              <Text className="para-text">
                We may collect personal information that you voluntarily provide
                when you:
              </Text>
              <Text className="para-text" paddingLeft="1rem">
                <ul>
                  <li>Visit or log into our website</li>
                  <li>Register for an account</li>
                  <li>Contact us for support or inquiries</li>
                  <li>Subscribe to newsletters or updates</li>
                  <li>Participate in surveys, contests, or promotions</li>
                </ul>
              </Text>
              <Text className="para-text">
                The information we collect may include, but is not limited to:
              </Text>
              <Text className="para-text" paddingLeft="1rem">
                <ul>
                  <li>Name</li>
                  <li>Email address</li>
                  <li>Company name</li>
                  <li>Job title</li>
                  <li>Contact information</li>
                  <li>Payment information (if applicable)</li>
                </ul>
              </Text>
              <Text fontSize="16px" fontWeight="500" color="var(--slate-400)">
                1.2. Information We Automatically Collect
              </Text>
              <Text className="para-text">
                When you use our website or services, we may collect certain
                information automatically, including:
              </Text>
              <Text className="para-text" paddingLeft="1rem">
                <ul>
                  <li>
                    Device information: We may collect information about your
                    device, including its type, operating system, browser type,
                    and IP address.
                  </li>
                  <li>
                    Usage information: We may collect data about your
                    interactions with our website and services, such as the
                    pages you visit, time spent on pages, links clicked, the
                    features you use, and the actions you take.
                  </li>
                  <li>
                    Cookies and similar technologies: We use cookies and similar
                    technologies to collect information about your browsing
                    activities. We may also combine this information collected
                    automatically with other data we receive from third-party
                    sources, such as data providers and marketing partners, to
                    create a more complete profile of you. You can manage your
                    cookie preferences through your browser settings.
                  </li>
                </ul>
              </Text>
              <Text
                className="text-lg"
                lineHeight="1.5rem"
                // fontSize={['1.875rem', '2.25rem', '3rem']}
                fontWeight="500"
                bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                bgClip="text"
                paddingBottom="6px"
              >
                2. How We Use Your Information
              </Text>
              <Text className="para-text">
                We use the information we collect for various purposes,
                including:
              </Text>
              <Text className="para-text" paddingLeft="1rem">
                <ul>
                  <li>Providing and improving our website and services</li>
                  <li>Personalizing your experience</li>
                  <li>Responding to your inquiries and support requests</li>
                  <li>
                    Sending you updates, newsletters, and promotional materials
                    (you can opt out at any time)
                  </li>
                  <li>Conducting research and analysis</li>
                  <li>Complying with legal obligations</li>
                </ul>
              </Text>
              <Text
                className="text-lg"
                lineHeight="1.5rem"
                // fontSize={['1.875rem', '2.25rem', '3rem']}
                fontWeight="500"
                bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                bgClip="text"
                paddingBottom="6px"
              >
                3. Disclosure of Your Information
              </Text>
              <Text className="para-text">
                We may share your information with third parties in the
                following circumstances:
              </Text>
              <Text className="para-text" paddingLeft="1rem">
                <ul>
                  <li>With your consent</li>
                  <li>
                    To comply with legal obligations or respond to lawful
                    requests
                  </li>
                  <li>To protect our rights, privacy, safety, or property</li>
                  <li>
                    In connection with a merger, acquisition, or sale of all or
                    a portion of our assets (you will be notified via email
                    and/or a prominent notice on our website)
                  </li>
                </ul>
              </Text>
              <Text
                className="text-lg"
                lineHeight="1.5rem"
                // fontSize={['1.875rem', '2.25rem', '3rem']}
                fontWeight="500"
                bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                bgClip="text"
                paddingBottom="6px"
              >
                4. Data Security
              </Text>
              <Text className="para-text">
                We take the security of your information seriously and employ
                reasonable administrative, technical, and physical safeguards to
                protect it. However, no method of transmission over the internet
                or electronic storage is completely secure, and we cannot
                guarantee absolute security.
              </Text>
              <Text
                className="text-lg"
                lineHeight="1.5rem"
                // fontSize={['1.875rem', '2.25rem', '3rem']}
                fontWeight="500"
                bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                bgClip="text"
                paddingBottom="6px"
              >
                5. Your Choices
              </Text>
              <Text className="para-text">
                You have the following rights regarding your personal
                information:
              </Text>
              <Text className="para-text" paddingLeft="1rem">
                <ul>
                  <li>
                    Access: You can request access to the personal information
                    we hold about you.
                  </li>
                  <li>
                    Correction: You can request that we correct inaccurate or
                    incomplete information.
                  </li>
                  <li>
                    Deletion: You can request the deletion of your personal
                    information, subject to legal obligations.
                  </li>
                  <li>
                    Opt-out: You can opt out of receiving marketing
                    communications from us at any time.
                  </li>
                </ul>
              </Text>
              <Text className="para-text">
                To exercise these rights or make inquiries about your personal
                information, please contact us at info@zinkml.com.
              </Text>
              <Text
                className="text-lg"
                lineHeight="1.5rem"
                // fontSize={['1.875rem', '2.25rem', '3rem']}
                fontWeight="500"
                bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                bgClip="text"
                paddingBottom="6px"
              >
                6. Changes to this Privacy Policy
              </Text>
              <Text className="para-text">
                We may update this Privacy Policy from time to time to reflect
                changes in our practices or for other operational, legal, or
                regulatory reasons. We will notify you of any material changes
                by posting the updated Privacy Policy on our website. Your
                continued use of our website and services after such changes
                will constitute your acceptance of the updated Privacy Policy.
              </Text>
              <Text
                className="text-lg"
                lineHeight="1.5rem"
                // fontSize={['1.875rem', '2.25rem', '3rem']}
                fontWeight="500"
                bgGradient="linear-gradient(0deg, var(--pink-300), var(--sky-200))"
                bgClip="text"
                paddingBottom="6px"
              >
                7. Contact Us
              </Text>
              <Text className="para-text">
                If you have any questions or concerns about this Privacy Policy
                or our data practices, please contact us at info@zinkml.com.
              </Text>
              <Text className="para-text">
                Thank you for choosing ZinkML Technologies Private Limited
                Company. We are dedicated to protecting your privacy and
                ensuring the security of your personal information as we
                continue to deliver global data science solutions.
              </Text>
            </Flex>
          </Grid>
        </Flex>
      </Flex>
      <CtaFooter1 />
      <FooterNew />
    </ChakraProvider>
  );
}

import React from 'react';
import NavComp from './NavComp';
import CtaFooter1 from './CtaFooter1';
import FooterNew from './FooterNew';
import CustomHubSpotForm from './CustomHubspotForm';
import { Helmet } from 'react-helmet';

export function RequestDemoForm() {
  return (
    <div>
      <Helmet>
        <title>ZinkML: Book a Demo</title>
        <link rel="canonical" href="https://zinkml.com/request-demo" />
        <meta
          name="description"
          content="Get a quick demo of our Data Platform"
        />
        <meta property="og:title" content="ZinkML: Book a Demo" />
        <meta
          name="og:description"
          content="Get a quick demo of our Data Platform"
        />
        <meta name="og:url" content="https://zinkml.com/request-demo" />

        <meta property="twitter:title" content="ZinkML: Book a Demo" />
        <meta
          name="twitter:description"
          content="Get a quick demo of our Data Platform"
        />
        <meta name="twitter:url" content="https://zinkml.com/request-demo" />
      </Helmet>
      <NavComp />
      <CustomHubSpotForm
        portalId="46788316"
        formId="c8755b3c-86a0-4bbd-8c14-2beb2a4eb044"
      />
      <CtaFooter1 />
      <FooterNew />
    </div>
  );
}
